import { transition } from "assets/jss/mainStyle.js";

const appStyle = {
  "@global": {
    body: {
      margin: 0,
      backgroundImage: theme => theme.backgroundImg
    }
  },
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  mainPanel: {
    overflow: "auto",
    position: "relative", 
    float: "right",
    ...transition,
    height: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    height: "100%"
  },
  container: {
    height: "100%"
  },
  map: {
    marginTop: "70px"
  },
  fixedMenu: {
    fontWeight: "300",
    fontFamily: "'Open Sans',  sans-serif",
    lineHeight: "1.5em",
    position: "absolute",
    top: "7px",
    left: "5px",
    width: "30px",
    zIndex: "1031",
    borderRadius: "0 8px 8px 0px",
    textAlign: "center",
    padding: "10px",
    color: "#FFFFFF"
  },
  fixedConfigMenu: {
    fontWeight: "300",
    fontFamily: "'Open Sans',  sans-serif",
    lineHeight: "1.5em",
    position: "absolute",
    top: "7px",
    right: "5px",
    width: "30px",
    zIndex: "1031",
    borderRadius: "0 8px 8px 0px",
    textAlign: "center",
    padding: "10px",
    color: "#FFFFFF"
  },
  hiddeConfig: {
    display: "none"
  }
};

export default appStyle;
