/* eslint-disable no-unused-vars */
import {
  fetchGenerator1DataStart,
  fetchGenerator1DataSuccess,
  fetchGenerator1DataFail,
  fetchGenerator2DataStart,
  fetchGenerator2DataSuccess,
  fetchGenerator2DataFail,
  fetchGenerator3DataStart,
  fetchGenerator3DataSuccess,
  fetchGenerator3DataFail,
  initializeStoreDataStart,
  initializeStoreDataSuccess,
  initializeStoreDataFail
} from "./actions";

import { handleActions } from "redux-actions";

const initialState = {
  generator1: {
    mainData: {
      oil: 0,
      refTemp: 0,
      oilTemp: 0,
      gas: 0,
      batery: 0,
      frec: 0,
      rpm: 0,
      totalPotenciaReactiva: 0,
      factorPotencial: 0
    },
    parametrosL1: {
      voltaje: 0,
      corriente: 0,
      potencia: 0,
      potenciaReactiva: 0,
      potenciaReactivaPorcentaje: 0
    },
    parametrosL2: {
      voltaje: 0,
      corriente: 0,
      potencia: 0,
      potenciaReactiva: 0,
      potenciaReactivaPorcentaje: 0
    },
    parametrosL3: {
      voltaje: 0,
      corriente: 0,
      potencia: 0,
      potenciaReactiva: 0,
      potenciaReactivaPorcentaje: 0
    },
    voltageChart: {
      MinValue: 0,
      MaxValue: 0,
      linea1Color: "blue",
      linea1Name: "Línea 1",
      linea1Min: 0,
      linea1Avg: 0,
      linea1Max: 0,
      linea2Color: "red",
      linea2Name: "Línea 2",
      linea2Min: 0,
      linea2Avg: 0,
      linea2Max: 0,
      linea3Color: "green",
      linea3Name: "Línea 3",
      linea3Min: 0,
      linea3Avg: 0,
      linea3Max: 0,
      serie: []
    },
    powerChart: {
      MinValue: 0,
      MaxValue: 0,
      linea1Color: "blue",
      linea1Name: "Línea 1",
      linea1Min: 0,
      linea1Avg: 0,
      linea1Max: 0,
      linea2Color: "red",
      linea2Name: "Línea 2",
      linea2Min: 0,
      linea2Avg: 0,
      linea2Max: 0,
      linea3Color: "green",
      linea3Name: "Línea 3",
      linea3Min: 0,
      linea3Avg: 0,
      linea3Max: 0,
      serie: []
    },
    currentChart: {
      MinValue: 0,
      MaxValue: 0,
      linea1Color: "blue",
      linea1Name: "Línea 1",
      linea1Min: 0,
      linea1Avg: 0,
      linea1Max: 0,
      linea2Color: "red",
      linea2Name: "Línea 2",
      linea2Min: 0,
      linea2Avg: 0,
      linea2Max: 0,
      linea3Color: "green",
      linea3Name: "Línea 3",
      linea3Min: 0,
      linea3Avg: 0,
      linea3Max: 0,
      serie: []
    }
  },
  generator2: {
    mainData: {
      oil: 0,
      refTemp: 0,
      oilTemp: 0,
      gas: 0,
      batery: 0,
      frec: 0,
      rpm: 0,
      totalPotenciaReactiva: 0,
      factorPotencial: 0
    },
    parametrosL1: {
      voltaje: 0,
      corriente: 0,
      potencia: 0,
      potenciaReactiva: 0,
      potenciaReactivaPorcentaje: 0
    },
    parametrosL2: {
      voltaje: 0,
      corriente: 0,
      potencia: 0,
      potenciaReactiva: 0,
      potenciaReactivaPorcentaje: 0
    },
    parametrosL3: {
      voltaje: 0,
      corriente: 0,
      potencia: 0,
      potenciaReactiva: 0,
      potenciaReactivaPorcentaje: 0
    },
    voltageChart: {
      MinValue: 0,
      MaxValue: 0,
      linea1Color: "blue",
      linea1Name: "Línea 1",
      linea1Min: 0,
      linea1Avg: 0,
      linea1Max: 0,
      linea2Color: "red",
      linea2Name: "Línea 2",
      linea2Min: 0,
      linea2Avg: 0,
      linea2Max: 0,
      linea3Color: "green",
      linea3Name: "Línea 3",
      linea3Min: 0,
      linea3Avg: 0,
      linea3Max: 0,
      serie: []
    },
    powerChart: {
      MinValue: 0,
      MaxValue: 0,
      linea1Color: "blue",
      linea1Name: "Línea 1",
      linea1Min: 0,
      linea1Avg: 0,
      linea1Max: 0,
      linea2Color: "red",
      linea2Name: "Línea 2",
      linea2Min: 0,
      linea2Avg: 0,
      linea2Max: 0,
      linea3Color: "green",
      linea3Name: "Línea 3",
      linea3Min: 0,
      linea3Avg: 0,
      linea3Max: 0,
      serie: []
    },
    currentChart: {
      MinValue: 0,
      MaxValue: 0,
      linea1Color: "blue",
      linea1Name: "Línea 1",
      linea1Min: 0,
      linea1Avg: 0,
      linea1Max: 0,
      linea2Color: "red",
      linea2Name: "Línea 2",
      linea2Min: 0,
      linea2Avg: 0,
      linea2Max: 0,
      linea3Color: "green",
      linea3Name: "Línea 3",
      linea3Min: 0,
      linea3Avg: 0,
      linea3Max: 0,
      serie: []
    }
  },
  generator3: {
    mainData: {
      oil: 0,
      refTemp: 0,
      oilTemp: 0,
      gas: 0,
      batery: 0,
      frec: 0,
      rpm: 0,
      totalPotenciaReactiva: 0,
      factorPotencial: 0
    },
    parametrosL1: {
      voltaje: 0,
      corriente: 0,
      potencia: 0,
      potenciaReactiva: 0,
      potenciaReactivaPorcentaje: 0
    },
    parametrosL2: {
      voltaje: 0,
      corriente: 0,
      potencia: 0,
      potenciaReactiva: 0,
      potenciaReactivaPorcentaje: 0
    },
    parametrosL3: {
      voltaje: 0,
      corriente: 0,
      potencia: 0,
      potenciaReactiva: 0,
      potenciaReactivaPorcentaje: 0
    },
    voltageChart: {
      MinValue: 0,
      MaxValue: 0,
      linea1Color: "blue",
      linea1Name: "Línea 1",
      linea1Min: 0,
      linea1Avg: 0,
      linea1Max: 0,
      linea2Color: "red",
      linea2Name: "Línea 2",
      linea2Min: 0,
      linea2Avg: 0,
      linea2Max: 0,
      linea3Color: "green",
      linea3Name: "Línea 3",
      linea3Min: 0,
      linea3Avg: 0,
      linea3Max: 0,
      serie: []
    },
    powerChart: {
      MinValue: 0,
      MaxValue: 0,
      linea1Color: "blue",
      linea1Name: "Línea 1",
      linea1Min: 0,
      linea1Avg: 0,
      linea1Max: 0,
      linea2Color: "red",
      linea2Name: "Línea 2",
      linea2Min: 0,
      linea2Avg: 0,
      linea2Max: 0,
      linea3Color: "green",
      linea3Name: "Línea 3",
      linea3Min: 0,
      linea3Avg: 0,
      linea3Max: 0,
      serie: []
    },
    currentChart: {
      MinValue: 0,
      MaxValue: 0,
      linea1Color: "blue",
      linea1Name: "Línea 1",
      linea1Min: 0,
      linea1Avg: 0,
      linea1Max: 0,
      linea2Color: "red",
      linea2Name: "Línea 2",
      linea2Min: 0,
      linea2Avg: 0,
      linea2Max: 0,
      linea3Color: "green",
      linea3Name: "Línea 3",
      linea3Min: 0,
      linea3Avg: 0,
      linea3Max: 0,
      serie: []
    }
  },
  loading1: false,
  loading2: false,
  loading3: false,
  updateTime1: null,
  updateTime2: null,
  updateTime3: null,
  error1: "",
  error2: "",
  error3: ""
};

const generators = handleActions(
  new Map([
    [
      fetchGenerator1DataStart,
      state => ({
        ...state,
        loading1: true,
        error1: ""
      })
    ],
    [
      fetchGenerator1DataSuccess,
      (state, action) => ({
        ...state,
        generator1: action.payload.data,
        loading1: false,
        updateTime1: action.payload.date
      })
    ],
    [
      fetchGenerator1DataFail,
      (state, action) => ({
        ...state,
        loading1: false,
        error1: action.payload.error
      })
    ],
    [
      fetchGenerator2DataStart,
      state => ({
        ...state,
        loading2: true,
        error2: ""
      })
    ],
    [
      fetchGenerator2DataSuccess,
      (state, action) => ({
        ...state,
        generator2: action.payload.data,
        loading2: false,
        updateTime2: action.payload.date
      })
    ],
    [
      fetchGenerator2DataFail,
      (state, action) => ({
        ...state,
        loading2: false,
        error2: action.payload.error
      })
    ],
    [
      fetchGenerator3DataStart,
      state => ({
        ...state,
        loading3: true,
        error3: ""
      })
    ],
    [
      fetchGenerator3DataSuccess,
      (state, action) => ({
        ...state,
        generator3: action.payload.data,
        loading3: false,
        updateTime3: action.payload.date
      })
    ],
    [
      fetchGenerator3DataFail,
      (state, action) => ({
        ...state,
        loading3: false,
        error3: action.payload.error
      })
    ],
    [
      initializeStoreDataStart,
      state => ({
        ...state,
        loading: true,
        error: ""
      })
    ],
    [
      initializeStoreDataSuccess,
      (state, action) => ({
        ...state,
        loading: false,
        updateTime: action.payload.date
      })
    ],
    [
      initializeStoreDataFail,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload.error
      })
    ]
  ]),
  initialState
);

export default generators;
