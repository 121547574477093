/* eslint-disable no-unused-vars */
import { createActions } from "redux-actions";
import { get } from "lodash-es";
import {
  getTidesAndCurrentsData,
  setTidesAndCurrentsData,
  getTidesAndCurrentsLastUpdate
} from "utils/localStorageHelper";
import mapTidesAndCurrents from "selectors/mapTidesAndCurrents";

var moment = require("moment");
require("moment/locale/es");

const updateTime = 600000;
const retryTime = 10000;

const actionOptions = {
  prefix: "TIDESANDCURRENTS", // Prefijo usado para los actions creados
  namespace: "-" // Separador usado entre el prefijo y el nombre del action
  // No usar "_" como separador, o se rompe el naming de las variables
};

export const {
  fetchDataStart,
  fetchDataSuccess,
  fetchDataFail,
  initializeStoreDataStart,
  initializeStoreDataSuccess,
  initializeStoreDataFail
} = createActions(
  {
    FETCH_DATA_START: undefined, // Este action no tiene payload
    FETCH_DATA_SUCCESS: data => ({ data }),
    FETCH_DATA_FAIL: error => ({ error }),
    INITIALIZE_STORE_DATA_START: undefined,
    INITIALIZE_STORE_DATA_SUCCESS: date => ({ date }),
    INITIALIZE_STORE_DATA_FAIL: error => ({ error })
  },
  actionOptions
);

export function initializeTidesStoreData() {
  return async dispatch => {
    dispatch(initializeStoreDataStart());

    try {
      let storeData = getTidesAndCurrentsData();
      // console.log('storeData => '+JSON.stringify(storeData));
      if (storeData == null) {
        
        await dispatch(fetchTidesData()); // ACTUALIZA FECHA
      } else {
        let data = mapTidesAndCurrents({
          currentsData: storeData.currentsData,
          depthData: storeData.depthData
        });

        dispatch(fetchDataSuccess(data));
        dispatch(initializeStoreDataSuccess());
      }
    } catch (error) {
      console.log("Error:", error);
      const message = get(
        error,
        "response.data.error_description",
        "Ocurrio un error al recuperar los datos de mareas."
      );
      dispatch(initializeStoreDataFail(message));
    }
  };
}

export function fetchTidesData() {
  return async (dispatch, getState, api) => {
    dispatch(fetchDataStart());

    try {
      const response = await api.oceanographicData.getDailyTidesAndCurrentsData();
      const responseDepths = await api.oceanographicData.getDailyDepthsData();

      setTidesAndCurrentsData({
        currentsData: response.data.CurrentLastDay[0].data,
        depthData: responseDepths.data.EcosondaLastDay[0].data
      });
      let data = mapTidesAndCurrents({
        currentsData: response.data.CurrentLastDay[0].data,
        depthData: responseDepths.data.EcosondaLastDay[0].data
      });

      dispatch(fetchDataSuccess(data));
    } catch (error) {
      console.log("Error:", error);
      const message = get(
        error,
        "response.data.error_description",
        "Ocurrio un error al recuperar los datos de mareas."
      );
      dispatch(fetchDataFail(message));
    }
  };
}

export function updateUnit() {
  return async dispatch => {
    dispatch(fetchDataStart());
    try {
      let storeData = getTidesAndCurrentsData();
      if (storeData != null) {
        let data = mapTidesAndCurrents({
          currentsData: storeData.currentsData,
          depthData: storeData.depthData
        });
        dispatch(fetchDataSuccess(data));
      } else {
        await dispatch(fetchTidesData());
      }
    } catch (error) {
      console.log("Error:", error);
      const message = get(
        error,
        "response.data.error_description",
        "Ocurrio un error al recuperar los datos de mareas."
      );
      dispatch(fetchDataFail(message));
    }
  };
}
