import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import styles from "assets/jss/components/sidebarStyle.js";
import MenuItem from "components/common/menuItem/menuItem";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();

  const { routes, logo, logoText } = props;

  var navlinks = (
    <List className={classes.list}>
      {routes
        .filter(x => !x.hideFromMenu)
        .map((prop, key) => {
          return <MenuItem {...prop} key={key} />;
        })}
      {/* <Link target="_blank" to={"/a-graph/anId"} style={{ overflowX: "visible", color: "black" }}>
    <MenuItem fitted key="graph" text="A Graph"/>
</Link> */}
    </List>
  );

  var brand = (
    <div className={classes.logo}>
      <div className={classes.logoImage}>
        <img src={logo} alt="logo" className={classes.img} />
      </div>
      {logoText} 
    </div>
  );

  return (
    <div>
      <Drawer
        variant="temporary"
        anchor={"left"}
        open={props.open}
        classes={{
          paper: classNames(classes.drawerPaper, {
            [classes.drawerPaperRTL]: props.rtlActive
          })
        }}
        onClose={props.handleDrawerToggle}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
      >
        {brand}
        <div className={classes.sidebarWrapper}>{navlinks}</div>

        <div
          className={classes.background}
          style={{ backgroundColor: "grey" }}
        />
      </Drawer>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  location: PropTypes.any
};
