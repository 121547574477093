import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { initializeStoreSettings } from "store/settings/actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  initialize: () => dispatch(initializeStoreSettings())
});

function SettingsManagerContainer(props) {
  React.useEffect(() => {
    // Obtengo los datos del servidor y lo guardo en el store
    props.initialize();
  }, []);

  return null;
}

SettingsManagerContainer.propTypes = {
  initialize: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsManagerContainer);
