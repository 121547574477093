import { createSelector } from "reselect";


const mapGeneralConditionsUPS = createSelector(
  data => data,
  data =>
    typeof data !== undefined && data !== null
      ? {
        ups_a: {
          inputV1: data.ups_a && data.ups_a.inputV1,
          inputV2:  data.ups_a && data.ups_a.inputV2,
          inputV3:  data.ups_a && data.ups_a.inputV3,
          inputCurrentL1:  data.ups_a && data.ups_a.inputCurrentL1,
          inputCurrentL2:  data.ups_a && data.ups_a.inputCurrentL2,
          inputCurrentL3:  data.ups_a && data.ups_a.inputCurrentL3,
          loadPhase1:  data.ups_a && data.ups_a.LoadPhase1,
          loadPhase2:  data.ups_a && data.ups_a.LoadPhase2,
          loadPhase3:  data.ups_a && data.ups_a.LoadPhase3,
          batteryVoltage:  data.ups_a && data.ups_a.BatteryVoltage,
          batteryPercentage:  data.ups_a && data.ups_a.BatteryPercentage,
          autonomy:  data.ups_a && data.ups_a.Autonomy,
          mode:  data.ups_a && data.ups_a.Mode,
          temperature:  data.ups_a && data.ups_a.Temperature,
          byPassV1:  data.ups_a && data.ups_a.BypassV1,
          byPassV2:  data.ups_a && data.ups_a.BypassV2,
          byPassV3:  data.ups_a && data.ups_a.BypassV3,
          outputV1:  data.ups_a && data.ups_a.OutputV1,
          outputV2:  data.ups_a && data.ups_a.OutputV2,
          outputV3:  data.ups_a && data.ups_a.OutputV3,
          outputCurrent1:  data.ups_a && data.ups_a.OutputCurrent1,
          outputCurrent2:  data.ups_a && data.ups_a.OutputCurrent2,
          outputCurrent3:  data.ups_a && data.ups_a.outputCurrent3,
          outputPower1:  data.ups_a && data.ups_a.OutputPower1,
          outputPower2:  data.ups_a && data.ups_a.OutputPower2,
          outputPower3:  data.ups_a && data.ups_a.OutputPower3,
        },
        ups_b: {
          inputV1:  data.ups_b && data.ups_b.inputV1,
          inputV2:  data.ups_b && data.ups_b.inputV2,
          inputV3:  data.ups_b && data.ups_b.inputV3,
          inputCurrentL1:  data.ups_b && data.ups_b.inputCurrentL1,
          inputCurrentL2:  data.ups_b && data.ups_b.inputCurrentL2,
          inputCurrentL3:  data.ups_b && data.ups_b.inputCurrentL3,
          loadPhase1:  data.ups_b && data.ups_b.LoadPhase1,
          loadPhase2:  data.ups_b && data.ups_b.LoadPhase2,
          loadPhase3:  data.ups_b && data.ups_b.LoadPhase3,
          batteryVoltage:  data.ups_b && data.ups_b.BatteryVoltage,
          batteryPercentage:  data.ups_b && data.ups_b.BatteryPercentage,
          autonomy:  data.ups_b && data.ups_b.Autonomy,
          mode:  data.ups_b && data.ups_b.Mode,
          temperature:  data.ups_b && data.ups_b.Temperature,
          byPassV1:  data.ups_b && data.ups_b.BypassV1,
          byPassV2:  data.ups_b && data.ups_b.BypassV2,
          byPassV3:  data.ups_b && data.ups_b.BypassV3,
          outputV1:  data.ups_b && data.ups_b.OutputV1,
          outputV2:  data.ups_b && data.ups_b.OutputV2,
          outputV3:  data.ups_b && data.ups_b.OutputV3,
          outputCurrent1:  data.ups_b && data.ups_b.OutputCurrent1,
          outputCurrent2:  data.ups_b && data.ups_b.OutputCurrent2,
          outputCurrent3:  data.ups_b && data.ups_b.outputCurrent3,
          outputPower1:  data.ups_b && data.ups_b.OutputPower1,
          outputPower2:  data.ups_b && data.ups_b.OutputPower2,
          outputPower3:  data.ups_b && data.ups_b.OutputPower3,
        },
        ups_c: {
          inputV1:  data.ups_c && data.ups_c.inputV1,
          inputV2:  data.ups_c && data.ups_c.inputV2,
          inputV3:  data.ups_c && data.ups_c.inputV3,
          inputCurrentL1:  data.ups_c && data.ups_c.inputCurrentL1,
          inputCurrentL2:  data.ups_c && data.ups_c.inputCurrentL2,
          inputCurrentL3:  data.ups_c && data.ups_c.inputCurrentL3,
          loadPhase1:  data.ups_c && data.ups_c.LoadPhase1,
          loadPhase2:  data.ups_c && data.ups_c.LoadPhase2,
          loadPhase3:  data.ups_c && data.ups_c.LoadPhase3,
          batteryVoltage:  data.ups_c && data.ups_c.BatteryVoltage,
          batteryPercentage:  data.ups_c && data.ups_c.BatteryPercentage,
          autonomy:  data.ups_c && data.ups_c.Autonomy,
          mode:  data.ups_c && data.ups_c.Mode,
          temperature:  data.ups_c && data.ups_c.Temperature,
          byPassV1:  data.ups_c && data.ups_c.BypassV1,
          byPassV2:  data.ups_c && data.ups_c.BypassV2,
          byPassV3:  data.ups_c && data.ups_c.BypassV3,
          outputV1:  data.ups_c && data.ups_c.OutputV1,
          outputV2:  data.ups_c && data.ups_c.OutputV2,
          outputV3:  data.ups_c && data.ups_c.OutputV3,
          outputCurrent1:  data.ups_c && data.ups_c.OutputCurrent1,
          outputCurrent2:  data.ups_c && data.ups_c.OutputCurrent2,
          outputCurrent3:  data.ups_c && data.ups_c.outputCurrent3,
          outputPower1:  data.ups_c && data.ups_c.OutputPower1,
          outputPower2:  data.ups_c && data.ups_c.OutputPower2,
          outputPower3:  data.ups_c && data.ups_c.OutputPower3,
        }
      }
      : {
        ups_a: {
          inputV1: 0,
          inputV2: 0,
          inputV3: 0,
          inputCurrentL1: 0,
          inputCurrentL2: 0,
          inputCurrentL3: 0,
          loadPhase1: 0,
          loadPhase2: 0,
          loadPhase3: 0,
          batteryVoltage: 0,
          batteryPercentage: 0,
          autonomy: 0,
          mode:  "None",
          temperature:  0,
          byPassV1:  0,
          byPassV2:  0,
          byPassV3:  0,
          outputV1:  0,
          outputV2:  0,
          outputV3:  0,
          outputCurrent1:  0,
          outputCurrent2:  0,
          outputCurrent3:  0,
          outputPower1:  0,
          outputPower2:  0,
          outputPower3:  0
        },
        ups_b: {
          inputV1: 0,
          inputV2: 0,
          inputV3: 0,
          inputCurrentL1: 0,
          inputCurrentL2: 0,
          inputCurrentL3: 0,
          loadPhase1: 0,
          loadPhase2: 0,
          loadPhase3: 0,
          batteryVoltage: 0,
          batteryPercentage: 0,
          autonomy: 0,
          mode:  "None",
          temperature:  0,
          byPassV1:  0,
          byPassV2:  0,
          byPassV3:  0,
          outputV1:  0,
          outputV2:  0,
          outputV3:  0,
          outputCurrent1:  0,
          outputCurrent2:  0,
          outputCurrent3:  0,
          outputPower1:  0,
          outputPower2:  0,
          outputPower3:  0
        },
        ups_c: {
          inputV1: 0,
          inputV2: 0,
          inputV3: 0,
          inputCurrentL1: 0,
          inputCurrentL2: 0,
          inputCurrentL3: 0,
          loadPhase1: 0,
          loadPhase2: 0,
          loadPhase3: 0,
          batteryVoltage: 0,
          batteryPercentage: 0,
          autonomy: 0,
          mode:  "None",
          temperature:  0,
          byPassV1:  0,
          byPassV2:  0,
          byPassV3:  0,
          outputV1:  0,
          outputV2:  0,
          outputV3:  0,
          outputCurrent1:  0,
          outputCurrent2:  0,
          outputCurrent3:  0,
          outputPower1:  0,
          outputPower2:  0,
          outputPower3:  0
        }
      }
);

export default mapGeneralConditionsUPS;
