import React from "react";
import { connect } from "react-redux";
// import CRUDHomeHours from "containers/modals/CRUDHomeHours";
// import TwoOptionsDialog from 'containers/modals/TwoOptionsDialog';
// import TaskDetails from 'containers/modals/TaskDetails';

export const ModalTypes = {
  // CRUDHOMEHOURS: 'CRUDHOMEHOURS',
  // TWOOPTIONSDIALOG: 'TWOOPTIONSDIALOG',
  // TASKDETAILS: 'TASKDETAILS'
};

const MODAL_COMPONENTS = {
  // CRUDHOMEHOURS: CRUDHomeHours,
  // TWOOPTIONSDIALOG: TwoOptionsDialog,
  // TASKDETAILS: TaskDetails,
};

const ModalRoot = props => {
  if (!props.modalType) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[props.modalType];
  return <SpecificModal />;
};

const mapStateToProps = state => ({
  modalType: state.modal.modalType
});

export default connect(mapStateToProps)(ModalRoot);
