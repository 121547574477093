import { createSelector } from "reselect";
import { getWindUnit } from "utils/localStorageHelper";
import { round } from "utils/math";

const mapMeteorologicalConditions = createSelector(
  data => data,
  data =>
    typeof data !== "undefined" && data !== null
      ? {
          temperature: {
            current: round(data.tempair, -1),
            min: round(data.min && data.min.tempair, -1),
            max: round(data.max && data.max.tempair, -1)
          },
          apparentTemperature: {
            current: getApparentTemperature(data.tempair, data.vientovel.med),
            min: round(data.min && data.min.tempair, -1),
            max: round(data.max && data.max.tempair, -1)
          },
          humidity: {
            current: round(data.humrel, 0),
            min: round(data.min && data.min.humrel, 0),
            max: round(data.max && data.max.humrel, 0)
          },
          atmosphericPressure: {
            current: round(data.presair, 0),
            min: round(data.min && data.min.presair, 0),
            max: round(data.max && data.max.presair, 0)
          },
          windSpeed: {
            current: convertWindUnit(data.vientovel.med),
            min: convertWindUnit(data.min && data.min.vientovel),
            max: convertWindUnit(data.max && data.max.vientovel)
          },
          windGust: {
            current: convertWindUnit(data.vientovel.max),
            min: convertWindUnit(data.min && data.min.rafaga),
            max: convertWindUnit(data.max && data.max.rafaga)
          },
          //agua
          annualRainfall: data.pacumulado && data.pacumulado.ann,
          monthlyRainfall: data.pacumulado && data.pacumulado.mes,
          weeklyRainfall: data.pacumulado && data.pacumulado.sem,
          dailyRainfall: data.precipita,
          windDirection: round(data.vientodir.med, -1),
          uvIndex: {
            current: Math.round(data.uv),
            min: Math.round(data.min && data.min.uv),
            max: Math.round(data.max && data.max.uv)
          }, 
          solarRadiation: {
            current: round(data.radiasolar, 0),
            min: round(data.min && data.min.radiasolar, 0),
            max: round(data.max &&  data.max.radiasolar, 0)
          },
          images: {
            image1: data.cam && data.cam.image1,
            image2: data.cam && data.cam.image2,
            image3: data.cam && data.cam.image3,
            image4: data.cam && data.cam.image4,
            image5: data.cam && data.cam.image5,
            image6: data.cam && data.cam.image6
          }
        }
      : {
          temperature: {
            current: 0,
            min: 0,
            max: 0
          },
          apparentTemperature: {
            current: 0,
            min: 0,
            max: 0
          },
          humidity: {
            current: 0,
            min: 0,
            max: 0
          },
          atmosphericPressure: {
            current: 0,
            min: 0,
            max: 0
          },
          windSpeed: {
            current: 0,
            min: 0,
            max: 0
          },
          windGust: {
            current: 0,
            min: 0,
            max: 0
          },
          annualRainfall: 0,
          monthlyRainfall: 0,
          weeklyRainfall: 0,
          dailyRainfall: 0,
          windDirection: 0,
          windGustDirection: 0,
          uvIndex: {
            current: 0,
            min: 0,
            max: 0
          },
          solarRadiation: {
            current: 0,
            min: 0,
            max: 0
          },
          images: {
            image1: null,
            image2: null,
            image3: null,
            image4: null,
            image5: null,
            image6: null
          }
        }
);

export default mapMeteorologicalConditions;

const getApparentTemperature = (tempair, windspeed) => {
  return round((
    13.12 +
      0.6215 * tempair -
      11.37 * Math.pow(windspeed, 0.16) +
      0.3965 * tempair * Math.pow(windspeed, 0.16))
  , -1);
};

const convertWindUnit = windspeed => {
  const windUnit = getWindUnit();

  switch (windUnit) {
    case "knots":
      return round(windspeed * 1.94384449, -1);
    case "km/h":
      return round(windspeed * 3.6, -1);
    case "m/s":
      return round(windspeed, -1);
    default:
      return round(windspeed, -1);
  }
};
