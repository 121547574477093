import { createSelector } from "reselect";


const mapGeneralConditionsEMeter = createSelector(
  data => data,
  data =>
    typeof data !== undefined && data !== null 
      ? {
        frecuency: data.analizador.Frecuencia && data.analizador.Frecuencia,
        voltage: data.analizador.Voltaje && data.analizador.Voltaje,
        current: data.analizador.Corriente && data.analizador.Corriente,
        power: data.analizador.Power && data.analizador.Power
      }
      : {
        frecuency: 0,
        voltage: 0,
        current: 0,
        power: 0,
      }
);

export default mapGeneralConditionsEMeter;
