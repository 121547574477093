/* eslint-disable no-unused-vars */
import {
  fetchDataUpsStart,
  fetchDataEnergyMeterStart,
  fetchDataTempHumStart,
  fetchDataDoorStart,

  fetchDataUpsSuccess,
  fetchDataEnergyMeterSuccess,
  fetchDataTempHumSuccess,
  fetchDataDoorSuccess,

  fetchDataUpsFail,
  fetchDataEnergyMeterFail,
  fetchDataTempHumFail,
  fetchDataDoorFail,
  initializeStoreDataStart,
  initializeStoreDataSuccess,
  initializeStoreDataFail
} from "./actions";

import { handleActions } from "redux-actions";

const initialState = {
  upsdata: {
    ups_a: {
      inputV1: 0,
      inputV2: 0,
      inputV3: 0,
      inputCurrentL1: 0,
      inputCurrentL2: 0,
      inputCurrentL3: 0,
      loadPhase1: 0,
      loadPhase2: 0,
      loadPhase3: 0,
      batteryVoltage: 0,
      batteryPercentage: 0,
      autonomy: 0
    },
    ups_b: {
      inputV1: 0,
      inputV2: 0,
      inputV3: 0,
      inputCurrentL1: 0,
      inputCurrentL2: 0,
      inputCurrentL3: 0,
      loadPhase1: 0,
      loadPhase2: 0,
      loadPhase3: 0,
      batteryVoltage: 0,
      batteryPercentage: 0,
      autonomy: 0
    },
    ups_c: {
      inputV1: 0,
      inputV2: 0,
      inputV3: 0,
      inputCurrentL1: 0,
      inputCurrentL2: 0,
      inputCurrentL3: 0,
      loadPhase1: 0,
      loadPhase2: 0,
      loadPhase3: 0,
      batteryVoltage: 0,
      batteryPercentage: 0,
      autonomy: 0
    }
  },
  emdata: {
    frecuency: 0,
    voltage: 0,
    current: 0,
    power: 0,
  },
  doordata: {
    estado: false
  },
  thdata: {
    temp: 0,
    hum: 0
  },
  loading: false,
  updateTime: null,
  error: ""
};

const generalConditions = handleActions(
  new Map([
    //UPS
    [
      fetchDataUpsStart,
      (state, action) => ({
        ...state,
        loading: true,
        error: ""
      })
    ],
    [
      fetchDataUpsSuccess,
      (state, action) => ({
        // Mapear correctamente los datos aca
        ...state,
        upsdata: action.payload.data,
        loading: false,
        updateTime: action.payload.data.date
      })
    ],
    [
      fetchDataUpsFail,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload.error
      })
    ],
    // TEMP - HUM
    [
      fetchDataTempHumStart,
      (state, action) => ({
        ...state,
        loading: true,
        error: ""
      })
    ],
    [
      fetchDataTempHumSuccess,
      (state, action) => (
        {
        // Mapear correctamente los datos aca
        ...state,
        thdata: action.payload.data,
        loading: false,
        updateTime: action.payload.data.date
      })
    ],
    [
      fetchDataTempHumFail,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload.error
      })
    ],
    // ENERGY METER
    [
      fetchDataEnergyMeterStart,
      (state, action) => ({
        ...state,
        loading: true,
        error: ""
      })
    ],
    [
      fetchDataEnergyMeterSuccess,
      (state, action) => (
        {
        // Mapear correctamente los datos aca
        ...state,
        emdata: action.payload.data,
        loading: false,
        updateTime: action.payload.data.date
      })
    ],
    [
      fetchDataEnergyMeterFail,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload.error
      })
    ],
    // DOOR
    [
      fetchDataDoorStart,
      (state, action) => ({
        ...state,
        loading: true,
        error: ""
      })
    ],
    [
      fetchDataDoorSuccess,
      (state, action) => (
        {
        // Mapear correctamente los datos aca
        ...state,
        doordata: action.payload.data,
        loading: false,
        updateTime: action.payload.data.date
      })
    ],
    [
      fetchDataDoorFail,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload.error
      })
    ],

    [
      initializeStoreDataStart,
      (state, action) => ({
        ...state,
        loading: true,
        error: ""
      })
    ],
    [
      initializeStoreDataSuccess,
      (state, action) => ({
        ...state,
        loading: false,
        updateTime: action.payload.date
      })
    ],
    [
      initializeStoreDataFail,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload.error
      })
    ]
  ]),
  initialState
);

export default generalConditions;
