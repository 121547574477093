/* eslint-disable no-unused-vars */
import {
  fetchDataStart,
  fetchDataSuccess,
  fetchDataFail,
  initializeStoreDataStart,
  initializeStoreDataSuccess,
  initializeStoreDataFail
} from "./actions";

import { handleActions } from "redux-actions";

const initialState = {
  data: {
    dates: [],
    keys: [],
    lineData: [],
    arrowData: []
  },
  loading: false,
  updateTime: null,
  error: ""
};

const tidesAndCurrents = handleActions(
  new Map([
    [
      fetchDataStart,
      state => ({
        ...state,
        loading: true,
        error: ""
      })
    ],
    [
      fetchDataSuccess,
      (state, action) => ({
        ...state,
        data: action.payload.data,
        loading: false,
        updateTime: action.payload.date
      })
    ],
    [
      fetchDataFail,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload.error
      })
    ],
    [
      initializeStoreDataStart,
      (state, action) => ({
        ...state,
        loading: true,
        error: ""
      })
    ],
    [
      initializeStoreDataSuccess,
      (state, action) => ({
        ...state,
        loading: false,
        updateTime: action.payload.date
      })
    ],
    [
      initializeStoreDataFail,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload.error
      })
    ]
  ]),
  initialState
);

export default tidesAndCurrents;
