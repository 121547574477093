import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import apiService from "api/api";

export const store = createStore(
  reducers,
  undefined,
  composeWithDevTools(applyMiddleware(thunk.withExtraArgument(apiService)))
);
