/* eslint-disable no-unused-vars */
import { get } from "lodash-es";
import { createActions } from "redux-actions";
import {
  initializeWaterStoreData,
  fetchWaterData
} from "store/waterConditions/actions";
import {
  initializeTidesStoreData,
  fetchTidesData
} from "store/tidesAndCurrents/actions";

var moment = require("moment");
require("moment/locale/es");

const actionOptions = {
  prefix: "OCEANOGRAPHICDATA",
  namespace: "-"
};

export const {
  getDataStart,
  getDataSuccess,
  getDataFail,
  initializeStoreDataStart,
  initializeStoreDataSuccess,
  initializeStoreDataFail
} = createActions(
  {
    GET_DATA_START: undefined,
    GET_DATA_SUCCESS: date => ({ date }),
    GET_DATA_FAIL: error => ({ error }),
    INITIALIZE_STORE_DATA_START: undefined,
    INITIALIZE_STORE_DATA_SUCCESS: date => ({ date }),
    INITIALIZE_STORE_DATA_FAIL: error => ({ error })
  },
  actionOptions
);

export function fetchOceanData(time) {
  return async dispatch => {
    dispatch(getDataStart());

    try {
      let formatedTime = moment(time, "x").format("YYYY-MM-DD H:mm");
      executeUpdateDispatchs(dispatch);
      dispatch(getDataSuccess(formatedTime));
    } catch (error) {
      console.log("Error:", error);
      const message = get(
        error,
        "response.data.error_description",
        "An error occurred while logging in. Please try again."
      );
      dispatch(getDataFail(message));
    }
  };
}

export function initializeOceanStoreData() {
  return async dispatch => {
    dispatch(initializeStoreDataStart());
    try {
      let formatedTime = moment().format("YYYY-MM-DD H:mm");
      executeInitialDispatchs(dispatch);
      dispatch(initializeStoreDataSuccess(formatedTime));
    } catch (error) {
      console.log("Error:", error);
      const message = get(
        error,
        "response.data.error_description",
        "Ocurrio un error al recuperar los datos oceanográficos."
      );
      dispatch(initializeStoreDataFail(message));
    }
  };
}

function executeInitialDispatchs(dispatch) {
  dispatch(initializeTidesStoreData());
  dispatch(initializeWaterStoreData());
}

function executeUpdateDispatchs(dispatch) {
  dispatch(fetchWaterData());
  dispatch(fetchTidesData());
}
