import routeList from "./routeList";
import React, { Suspense } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import SuspenseFallback from "components/common/suspenseFallback/suspenseFallback";

export const AppRoutes = (
  <Suspense fallback={<SuspenseFallback />}>
    <Switch>
      {routeList
        .filter(x => x.path)
        .map((prop, key) => {
          return (
            <Route
              exact
              path={prop.path}
              component={prop.component}
              key={key}
            />
          );
        })}
      <Redirect exact from="/" to="login" />
    </Switch>
  </Suspense>
);

export default AppRoutes;
