/* eslint-disable no-unused-vars */
import { get } from "lodash-es";
import mapDesalinationPlant from "selectors/mapDesalinationPlant";
import { createActions } from "redux-actions";

var moment = require("moment");
require("moment/locale/es");

const actionOptions = {
  prefix: "DESALINATIONPLANT",
  namespace: "-"
};

export const {
  fetchDataStart,
  fetchDataSuccess,
  fetchDataFail,
  initializeStoreDataStart,
  initializeStoreDataSuccess,
  initializeStoreDataFail
} = createActions(
  {
    FETCH_DATA_START: undefined,
    FETCH_DATA_SUCCESS: (data, date) => ({ data, date }),
    FETCH_DATA_FAIL: error => ({ error }),
    INITIALIZE_STORE_DATA_START: undefined,
    INITIALIZE_STORE_DATA_SUCCESS: date => ({ date }),
    INITIALIZE_STORE_DATA_FAIL: error => ({ error })
  },
  actionOptions
);

export function initializeDesalinationStoreData() {
  return async (dispatch, getState, api) => {
    dispatch(initializeStoreDataStart());

    try {
      const response = await api.desalinationPlant.getData();
      let data = mapDesalinationPlant(response.data[0]);
      dispatch(fetchDataSuccess(data, moment().format("YYYY-MM-DD H:mm")));

      dispatch(initializeStoreDataSuccess(moment().format("YYYY-MM-DD H:mm")));
    } catch (error) {
      //console.log("Error:", error);
      const message = get(
        error,
        "response.data.error_description",
        "Ocurrio un error al recuperar los datos de mareas."
      );
      dispatch(initializeStoreDataFail(message));
    }
  };
}

export function fetchDesalinationData(time) {
  return async (dispatch, getState, api) => {
    dispatch(fetchDataStart());
    try {
      let formatedTime = moment(time, "x").format("YYYY-MM-DD H:mm");

      const response = await api.desalinationPlant.getData();

      let data = mapDesalinationPlant(response.data[0]);

      dispatch(fetchDataSuccess(data, formatedTime));
    } catch (error) {
      console.log("Error:", error);
      const message = get(
        error,
        "response.data.error_description",
        "An error occurred while logging in. Please try again."
      );
      dispatch(fetchDataFail(message));
    }
  };
}
