import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import {
  fetchMeteoData,
  initializeMeteoStoreData
} from "store/meteorologicalConditions/actions";

import {
  fetchDataUps,
  fetchDataEnergyMeter,
  fetchDataTempHum,
  fetchDataDoor,
  initializeGeneralStoreData
} from "store/generalConditions/actions";

import feathers from "@feathersjs/client";
import socketio from "@feathersjs/socketio-client";
import { getAuthToken } from "utils/auth.js";

import { useHistory } from "react-router-dom";

const io = require("socket.io-client");
const host = `${process.env.REACT_APP_API_HOST}`;
const app = feathers();
const socket = io(host);

const token = getAuthToken();

app.configure(
  socketio(socket, {
    timeout: 20000
  })
);

const mapDispatchToProps = dispatch => ({
  initializeMeteoStoreData: () => dispatch(initializeMeteoStoreData()),
  initializeGeneralStoreData: () => dispatch(initializeGeneralStoreData()),

  getMeteoData: data => dispatch(fetchMeteoData(data)),
  getDataUps: data => dispatch(fetchDataUps(data)),
  getDataEnergyMeter: data => dispatch(fetchDataEnergyMeter(data)),
  getDataTempHum: data => dispatch(fetchDataTempHum(data)),
  getDataDoor: data => dispatch(fetchDataDoor(data)),
});

function DataControllerContainer(props) {

  const history = useHistory();

  const getMeteorologicData = response => {

    //SOLO ENVIAR LA ACTUALIZACION 
    const centro = sessionStorage.getItem('centro');
    //console.log('centro = '+centro);
    //console.log('response = '+JSON.stringify(response));
    if (response.centro === parseInt(centro)) {
      //console.log('la actualizacion PERTENECE centro seleccionado');
      props.getMeteoData(response);
    } else {
      //console.log('la actualizacion no pertenece al centro seleccionado');
    }
  };

  const getDataTempHum = response => {
    //SOLO ENVIAR LA ACTUALIZACION 
    const centro = sessionStorage.getItem('centro');
    if (response.centro === parseInt(centro)) {
      props.getDataTempHum(response);
    } 
  }

  const getDataUPS = response => {
    //SOLO ENVIAR LA ACTUALIZACION 
    const centro = sessionStorage.getItem('centro');
    if (response.centro === parseInt(centro)) {
      props.getDataUps(response);
    } 
  }

  const getDataEnergyMeter = response => {
    //SOLO ENVIAR LA ACTUALIZACION 
    const centro = sessionStorage.getItem('centro');
    if (response.centro === parseInt(centro)) {
      props.getDataEnergyMeter(response);
    } 
  }

  const getDataDoor = response => {
    //SOLO ENVIAR LA ACTUALIZACION 
    const centro = sessionStorage.getItem('centro');
    if (response.centro === parseInt(centro)) {
      props.getDataDoor(response);
    } 
  }


  React.useEffect(() => {


    if (token !== null && token !== undefined) {
      socket.on("connect", () => {
        socket.emit(
          "create",
          "authentication",
          {
            strategy: "jwt",
            accessToken: getAuthToken()
          },
          function (error, newAuthResult) {
            //console.log("token socketio OK : ", newAuthResult);

            if (error !== null && error !== undefined) {
              //console.log("token socketio 2 : ", error);
              sessionStorage.removeItem("loggedin")
              history.push('/login');
            }
          });
      });
    }

    props.initializeMeteoStoreData();


    app
      .service("meteo")
      .on("updateDataMeteoSensors", response => getMeteorologicData(response));

      app
      .service("thsensor")
      .on("updateDataTHsensor", response => getDataTempHum(response));

      app
      .service("ups")
      .on("updateDataUPS", response => getDataUPS(response));

      app
      .service("emeter")
      .on("updateDataEmeter", response => getDataEnergyMeter(response));

      app
      .service("door")
      .on("updateDataDoor", response => getDataDoor(response));


    return () => {
      app.service("meteo").removeListener("updateDataMeteoSensors");
      app.service("door").removeListener("updateDataDoor");
      app.service("ups").removeListener("updateDataUPS");
      app.service("thsensor").removeListener("updateDataTHsensor");
      app.service("emeter").removeListener("updateDataEmeter");

    };
  }, []);

  return null;
}

DataControllerContainer.propTypes = {
  getData: PropTypes.func
};

export default connect(
  null,
  mapDispatchToProps
)(DataControllerContainer);
