/* eslint-disable no-unused-vars */
import { createActions } from "redux-actions";
import { get } from "lodash-es";
import mapGenerators from "selectors/mapGenerators";
import { getGeneratorData, setGeneratorData } from "utils/localStorageHelper";

var moment = require("moment");
require("moment/locale/es");

const actionOptions = {
  prefix: "GENERATORS",
  namespace: "-"
};

export const {
  fetchGenerator1DataStart,
  fetchGenerator1DataSuccess,
  fetchGenerator1DataFail,
  fetchGenerator2DataStart,
  fetchGenerator2DataSuccess,
  fetchGenerator2DataFail,
  fetchGenerator3DataStart,
  fetchGenerator3DataSuccess,
  fetchGenerator3DataFail,
  initializeStoreDataStart,
  initializeStoreDataSuccess,
  initializeStoreDataFail
} = createActions(
  {
    FETCH_GENERATOR1_DATA_START: undefined,
    FETCH_GENERATOR1_DATA_SUCCESS: (data, date) => ({ data, date }),
    FETCH_GENERATOR1_DATA_FAIL: error => ({ error }),
    FETCH_GENERATOR2_DATA_START: undefined,
    FETCH_GENERATOR2_DATA_SUCCESS: (data, date) => ({ data, date }),
    FETCH_GENERATOR2_DATA_FAIL: error => ({ error }),
    FETCH_GENERATOR3_DATA_START: undefined,
    FETCH_GENERATOR3_DATA_SUCCESS: (data, date) => ({ data, date }),
    FETCH_GENERATOR3_DATA_FAIL: error => ({ error }),
    INITIALIZE_STORE_DATA_START: undefined,
    INITIALIZE_STORE_DATA_SUCCESS: date => ({ date }),
    INITIALIZE_STORE_DATA_FAIL: error => ({ error })
  },
  actionOptions
);

export function initializeGeneratorsStoreData(generator) {
  return async dispatch => {
    try {
      await dispatch(fetchGeneratorsData(moment().valueOf(), generator));
    } catch (error) {
      console.log("Error:", error);
      const message = get(
        error,
        "response.data.error_description",
        "Ocurrio un error al recuperar los datos de generadores."
      );
    }
  };
}

export function fetchGeneratorsData(time, generator) {
  return async (dispatch, getState, api) => {
    switch (generator) {
      case "1":
        dispatch(fetchGenerator1DataStart());
        break;
      case "2":
        dispatch(fetchGenerator2DataStart());
        break;
      case "3":
        dispatch(fetchGenerator3DataStart());
        break;
    }

    try {
      let formatedTime = moment(time, "x").format("YYYY-MM-DD H:mm");

      let responseGeneral = await api.generators.getData(generator);
      let responsePowerHistorical = await api.generators.getPowerHistorical(
        generator
      );
      let responseCurentHistorical = await api.generators.getCurrentHistorical(
        generator
      );
      let responseVoltageHistorical = await api.generators.getVoltageHistorical(
        generator
      );

      // console.log(responseGeneral);
      let data;

      if (responsePowerHistorical != null) {
        data = mapGenerators({
          generalData: responseGeneral.data[0],
          powerHistorical: responsePowerHistorical.data[0],
          currentHistorical: responseCurentHistorical.data[0],
          voltageHistorical: responseVoltageHistorical.data[0]
        });

        let storeData = {
          generalData: responseGeneral.data[0],
          powerHistorical: responsePowerHistorical.data[0],
          currentHistorical: responseCurentHistorical.data[0],
          voltageHistorical: responseVoltageHistorical.data[0]
        };
        setGeneratorData(storeData, generator);
      } else {
        let storeData = getGeneratorData(generator);
        if (storeData == null) {
          storeData = {
            generalData: null,
            powerHistorical: null,
            currentHistorical: null,
            voltageHistorical: null
          };
        }
        data = mapGenerators({
          generalData: responseGeneral,
          powerHistorical: storeData.powerHistorical,
          currentHistorical: storeData.currentHistorical,
          voltageHistorical: storeData.voltageHistorical
        });
      }

      switch (generator) {
        case "1":
          dispatch(fetchGenerator1DataSuccess(data, formatedTime));
          break;
        case "2":
          dispatch(fetchGenerator2DataSuccess(data, formatedTime));
          break;
        case "3":
          dispatch(fetchGenerator3DataSuccess(data, formatedTime));
          break;
      }
    } catch (error) {
      console.log("Error:", error);
      const message = get(
        error,
        "response.data.error_description",
        "An error occurred while logging in. Please try again."
      );

      switch (generator) {
        case 1:
          dispatch(fetchGenerator1DataFail(message));
          break;
        case 2:
          dispatch(fetchGenerator2DataFail(message));
          break;
        case 3:
          dispatch(fetchGenerator3DataFail(message));
          break;
      }
    }
  };
}
