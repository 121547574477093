/* eslint-disable no-unused-vars */
import {
  fetchDataStart,
  fetchDataSuccess,
  fetchDataFail,
  initializeStoreDataStart,
  initializeStoreDataSuccess,
  initializeStoreDataFail
} from "./actions";

import { handleActions } from "redux-actions";

const initialState = {
  data: {
    waterDischarge: {
      lmin: 0,
      m3: 0,
      step: 0
    },
    waterSuction: {
      lmin: 0,
      m3: 0,
      amp: 0,
      swtich: 1
    },
    macerationPump: {
      switch: 1,
      amp: 0
    },
    transferPump: {
      switch: 1,
      amp: 0,
      lmin: 0,
      m3: 0
    },
    dosingPump: {
      amp: 0,
      switch: 1
    },
    waterPond: 0,
    mudPond: 0,
    plantStatus: 0,
    totalTreated: 0,
    totalBlack: 0,
    totalSea: 0
  },
  loading: false,
  updateTime: "",
  error: ""
};

const treatmentPlant = handleActions(
  new Map([
    [
      fetchDataStart,
      state => ({
        ...state,
        loading: true,
        error: ""
      })
    ],
    [
      fetchDataSuccess,
      (state, action) => ({
        ...state,
        data: action.payload.data,
        loading: false,
        updateTime: action.payload.date
      })
    ],
    [
      fetchDataFail,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload.error
      })
    ],
    [
      initializeStoreDataStart,
      state => ({
        ...state,
        loading: true,
        error: ""
      })
    ],
    [
      initializeStoreDataSuccess,
      (state, action) => ({
        ...state,
        loading: false,
        updateTime: action.payload.date
      })
    ],
    [
      initializeStoreDataFail,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload.error
      })
    ]
  ]),
  initialState
);

export default treatmentPlant;
