import { createSelector } from "reselect";

const mapTreatmentPlant = createSelector(
  data => data,
  data =>
    typeof data !== "undefined" && data !== null
      ? {
          waterDischarge: {
            lmin: data.aguastratadaslmin,
            m3: data.aguastratadasm3h,
            step: data.etapa
          },
          waterSuction: {
            lmin: data.aguasmarlmin,
            m3: data.aguasmarm3h,
            amp: data.baguamarcorriente,
            switch: data.baguamarestado
          },
          macerationPump: {
            switch: data.bmaceraciondescestado,
            amp: data.bmaceraciondesccorriente
          },
          transferPump: {
            switch: data.baguanegraestado,
            amp: data.btransaguanegracorriente,
            lmin: data.aguasnegraslmin,
            m3: data.aguasnegrasm3h
          },
          dosingPump: {
            amp: data.bdosificaperoxicorriente,
            switch: data.bdosificaperoxiestado
          },
          waterPond: data.aguasnegrasnivel,
          mudPond: data.estanquelodonivel,
          plantStatus: data.estadoplanta,
          totalTreated: data.totaaguastratadames,
          totalBlack: data.totalaguasnegrames,
          totalSea: data.totalaguasmarmes
        }
      : {
          waterDischarge: {
            lmin: 0,
            m3: 0,
            step: 1
          },
          waterSuction: {
            lmin: 0,
            m3: 0,
            amp: 0,
            switch: 1
          },
          macerationPump: {
            switch: 1,
            amp: 0
          },
          transferPump: {
            switch: 1,
            amp: 0,
            lmin: 0,
            m3: 0
          },
          dosingPump: {
            amp: 0,
            switch: 1
          },
          waterPond: 2,
          mudPond: 2,
          plantStatus: 0, // 0 Stand By/ 1: Procesando/ 2:Falla
          totalTreated: 0,
          totalBlack: 0,
          totalSea: 0
        }
);

export default mapTreatmentPlant;
