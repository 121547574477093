import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import { Modal } from '@material-ui/core';
import feathers from "@feathersjs/client";
import socketio from "@feathersjs/socketio-client";
import io from "socket.io-client";
import { axiosInstance } from "api/api.js";
import { setAuthToken } from "utils/auth.js";
import { connect } from "react-redux";

import {
    updateLoginState
} from "store/login/actions";

import {
    updateWindUnitStorage
  } from "store/settings/actions";

const host_auth = `${process.env.REACT_APP_API_AUTHENTICATION}`;
const app = feathers();
const socket = io(host_auth);

app.configure(
    socketio(socket, {
        timeout: 20000
    })
);

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3),
        backgroundColor: "gray",
        marginTop: "10%",

    },
    button_login: {
        backgroundColor: '#f08024'
    },
    image_logo: {
        direction: "column",
        alignItems: "center",
        justify: "center",
    },
    root: {
        height: 300,
        flexGrow: 1,
        minWidth: 300,
        transform: 'translateZ(0)',
        // The position fixed scoping doesn't work in IE 11.
        // Disable this demo to preserve the others.
        '@media all and (-ms-high-contrast: none)': {
            display: 'none',
        },
    },

    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: 400,
        backgroundColor: theme.palette.background.paper,
        opacity: '80%',
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const mapDispatchToProps = dispatch => ({
    updateLogedIn: data => dispatch(updateLoginState(data)),
    updateWindUnit: unit => dispatch(updateWindUnitStorage(unit)),

});


const Login = (props) => {
    const history = useHistory();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [showResults, setShowResults] = React.useState(false)
    const [flg, setFlg] = useState(true);
    const classes = useStyles();
    var mostrar = false;
    useEffect(() => {
        sessionStorage.clear();
    });

    const ShowError = () => {
        return (
            <div ref={rootRef}>
                <Modal
                    disablePortal
                    disableEnforceFocus
                    disableAutoFocus
                    open
                    aria-labelledby="server-modal-title"
                    aria-describedby="server-modal-description"
                    className={classes.modal}
                    container={() => rootRef.current}
                >
                    <div className={classes.paper}>
                        <h2 id="server-modal-title">Error en autenticacion</h2>
                        <h3 id="server-modal-description">Usuario o Contraseña invalida, vuelva a intentar</h3>
                    </div>
                </Modal>
            </div>
        );
    }

    const rootRef = React.useRef(null);
    var formulario = (

        <>
            { showResults ? <ShowError /> : null}
            <Container className={classes.container} maxWidth="xs">
                <form >
                    <Grid container spacing={3} >
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField fullWidth label="Email"
                                        onChange={(e) => { setEmail(e.target.value) }}
                                        name="email" size="small" variant="outlined" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Password"
                                        name="password"
                                        size="small"
                                        type="password"
                                        variant="outlined"
                                        onChange={(e) => { setPassword(e.target.value) }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Button className={classes.button_login} fullWidth onClick={() => {
                                //console.log(process.env.REACT_APP_API_AUTHENTICATION);

                                axiosInstance.post(host_auth, { strategy: "local", usuarioMail: email, usuarioPassword: password }).then(data => {
                                    sessionStorage.setItem('token', data.data.accessToken);
                                    sessionStorage.setItem('empresa', data.data.user.empresaId);
                                    sessionStorage.setItem('centro', data.data.user.centroId);
                                    sessionStorage.setItem('loggedin', true);
                                    sessionStorage.setItem('centroNombre', data.data.user.centroNombre);

                                    //("token AXIOS OK : ", data.data.accessToken);

                                     props.updateLogedIn(true);
                                     props.updateWindUnit("km/h");


                                    // setInterval(()=>{
                                    //     window.location.reload(false);
                                    //   },1000);

                                    setAuthToken(data.data.accessToken);
                                    history.push(
                                        {
                                            pathname: "/iotMap",
                                            state: {
                                                token: data.data.accessToken,
                                                loggedin: true
                                            }
                                        });

                                }).catch(error => {
                                    //console.log(error.response);
                                    setShowResults(true);
                                    setInterval(() => { setShowResults(false) }, 3000);

                                });

                            }} variant="contained">Login
            </Button>
                        </Grid>
                    </Grid>
                </form>
                <Grid container xs={24} spacing={3} justify="center"  >
                    <img style={{ width: 300, height: 'auto', maxHeight: 100, marginTop: 10 }} src={require('assets/img/logo.png')} />
                </Grid>

            </Container>

        </>

    );

    return (
        <>

            {formulario}
        </>
    );
}

// export default Login;

export default connect(
    null,
    mapDispatchToProps
)(Login);