// @material-ui/icons
import React from "react";
import { WbSunny, InvertColors, Waves } from "@material-ui/icons";
import LocalDrinkIcon from "@material-ui/icons/LocalDrink";
import BatteryChargingFull from "@material-ui/icons/BatteryChargingFull";
import ShutterSpeedIcon from "@material-ui/icons/ShutterSpeed";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';

const MeteorologicalConditionsContainer = React.lazy(() =>
  import("containers/pages/meteorologicalConditions/meteorologicalConditions")
);

const GeneralConditionsContainer = React.lazy(() =>
  import("containers/pages/generalConditions/generalConditions")
);

const ImageSearchContainer = React.lazy(() =>
  import("containers/pages/imageSearch/imageSearch")
);
const IotMapContainer = React.lazy(() =>
  import("containers/pages/iotMap/iotMap")
);
// const WaterConditionsContainer = React.lazy(() =>
//   import("containers/pages/waterConditions/waterConditions")
// );
// const TidesAndCurrentsContainer = React.lazy(() =>
//   import("containers/pages/tidesAndCurrents/tidesAndCurrents")
// );
// const Generator1Container = React.lazy(() =>
//   import("containers/pages/generators/generator1")
// );
// const Generator2Container = React.lazy(() =>
//   import("containers/pages/generators/generator2")
// );
// const Generator3Container = React.lazy(() =>
//   import("containers/pages/generators/generator3")
// );
// const DesalinationPlantContainer = React.lazy(() =>
//   import("containers/pages/desalinationPlant/desalinationPlant")
// );

// const TreatmentPlantContainer = React.lazy(() =>
//   import("containers/pages/treatmentPlant/treatmentPlant")
// );

const Login = React.lazy(() =>
  import("containers/pages/login/Login")
  
);

const Logout = React.lazy(() =>
  import("containers/pages/logout/Logout")
);

const routes = [
  {
    path: "/iotMap",
    name: "Mapa General",
    icon: RoomOutlinedIcon,
    component: IotMapContainer,
    loggedIn: false,
    hasSubmenu: false
  },
  {
    path: "/general",
    name: "Condiciones generales",
    icon: SettingsRemoteIcon,
    component: GeneralConditionsContainer,
    loggedIn: false,
    hasSubmenu: false
  },
  {
    path: "/meteorological",
    name: "Condiciones meteorológicas",
    icon: WbSunny,
    component: MeteorologicalConditionsContainer,
    loggedIn: false,
    hasSubmenu: false
  },
  {
    path: "/imagesearch",
    name: "Histórico Imágenes",
    icon: WbSunny,
    component: ImageSearchContainer,
    loggedIn: false,
    hasSubmenu: false
   },

  // {
  //   path: "/waterconditions",
  //   name: "Condiciones del agua",
  //   icon: InvertColors,
  //   component: WaterConditionsContainer,
  //   loggedIn: false,
  //   hasSubmenu: false,
  //   hideFromMenu: true
  // },
  // {
  //   path: "/tidesandcurrents",
  //   name: "Mareas y corrientes",
  //   icon: Waves,
  //   component: TidesAndCurrentsContainer,
  //   loggedIn: false,
  //   hasSubmenu: false,
  //   hideFromMenu: true
  // },
  // {
  //   // path: "/generators",
  //   name: "Generadores",
  //   icon: BatteryChargingFull,
  //   // component: GeneratorsContainer,
  //   loggedIn: false,
  //   hasSubmenu: true,
  //   hideFromMenu: true,
  //   subMenuList: [
  //     {
  //       path: "/generator1",
  //       name: "Generador 1"
  //     },
  //     {
  //       path: "/generator2",
  //       name: "Generador 2"
  //     },
  //     {
  //       path: "/generator3",
  //       name: "Generador 3"
  //     }
  //   ]
  // },
  // {
  //   path: "/generator1",
  //   name: "Generador 1",
  //   icon: ShutterSpeedIcon,
  //   component: Generator1Container,
  //   loggedIn: false,
  //   hasSubmenu: false,
  //   hideFromMenu: true
  // },
  // {
  //   path: "/generator2",
  //   name: "Generador 2",
  //   icon: ShutterSpeedIcon,
  //   component: Generator2Container,
  //   showMenu: false,
  //   loggedIn: false,
  //   hideFromMenu: true
  // },
  // {
  //   path: "/generator3",
  //   name: "Generador 3",
  //   icon: ShutterSpeedIcon,
  //   component: Generator3Container,
  //   loggedIn: false,
  //   hasSubmenu: false,
  //   hideFromMenu: true
  // },
  // {
  //   path: "/desalinationplant",
  //   name: "Planta desalinizadora",
  //   icon: ShutterSpeedIcon,
  //   component: DesalinationPlantContainer,
  //   loggedIn: false,
  //   hasSubmenu: false,
  //   hideFromMenu: true
  // },
  // {
  //   path: "/treatmentplant",
  //   name: "Planta de tratamiento de aguas negras",
  //   icon: LocalDrinkIcon,
  //   component: TreatmentPlantContainer,
  //   loggedIn: false,
  //   hasSubmenu: false,
  //   hideFromMenu: true
  // },
  {
    path: "/login",
    name: "Logout",
    icon: ExitToAppIcon,
    component: Logout,
    loggedIn: false,
    hasSubmenu: false
    // hideFromMenu: true
  }

];  

export default routes;
