/* eslint-disable no-unused-vars */
import { get } from "lodash-es";
import mapMeteorologicalConditions from "selectors/mapMeteorologicalConditions";
import { createActions } from "redux-actions";

import {
  getMeteorologicalData,
  setMeteorologicalData
} from "utils/localStorageHelper";

var moment = require("moment");
require("moment/locale/es");

const updateTime = 600000;
const retryTime = 10000;

// ------------------- //
// ACTIONS SINCRÓNICOS //
// ------------------- //

const actionOptions = {
  prefix: "METEOROLOGICAL",
  namespace: "-"
};

export const {
  fetchDataStart,
  fetchDataSuccess,
  fetchDataFail,
  initializeStoreDataStart,
  initializeStoreDataSuccess,
  initializeStoreDataFail,
  setNextUpdate,
  updateUnitDataStore
} = createActions(
  {
    FETCH_DATA_START: undefined, 
    FETCH_DATA_SUCCESS: data => ({ data }),
    FETCH_DATA_FAIL: error => ({ error }),
    INITIALIZE_STORE_DATA_START: undefined,
    INITIALIZE_STORE_DATA_SUCCESS: date => ({ date }),
    INITIALIZE_STORE_DATA_FAIL: error => ({ error }),
    SET_NEXT_UPDATE: date => ({ date }),
    UPDATE_UNIT_DATA: date => ({ date })
  },
  actionOptions
);

export function fetchMeteoData(response) {
  return async (dispatch, getState, api) => {
    dispatch(fetchDataStart());
    let storedData = getMeteorologicalData();
    let data = response.em;
    data = updateStoreData(storedData, data);
    try {
      setMeteorologicalData(data);
      dispatch(fetchDataSuccess(mapMeteorologicalConditions(data)));
      dispatch(
        initializeStoreDataSuccess(
          moment(response.horamuestra, "x").format("YYYY-MM-DD H:mm")
        )
      );
    } catch (error) {
      console.log("Error:", error);
      const message = get(
        error,
        "response.data.error_description",
        "Ocurrio un error al recuperar los datos meteorologicos."
      );
      dispatch(fetchDataFail(message));
    }
  };
}

export function initializeMeteoStoreData() {
  return async (dispatch, getState, api) => {
    dispatch(initializeStoreDataStart());

    try {
      let storedData = getMeteorologicalData();
      if (storedData == null || isNaN(storedData.tempMin)) {
        const response = await api.meteorologicalConditions.getData();
        ///console.log('initializeMeteoStoreData --- '+JSON.stringify(response)); 
        let data = response.data[0].em;
        setMeteorologicalData(data);
        dispatch(fetchDataSuccess(mapMeteorologicalConditions(data)));
        dispatch(
          initializeStoreDataSuccess(
            moment(response.data[0].horamuestra).format("YYYY-MM-DD H:mm")
          )
        );
      } else {
        dispatch(fetchDataSuccess(mapMeteorologicalConditions(storedData)));
        dispatch(
          initializeStoreDataSuccess(moment().format("YYYY-MM-DD H:mm"))
        );
      }
    } catch (error) {
      console.log("Error:", error);
      const message = get(
        error,
        "response.data.error_description",
        "Ocurrio un error al recuperar los datos meteorologicos."
      );
      dispatch(initializeStoreDataFail(message));
    }
  };
}

export function updateUnitData() {
  return async dispatch => {
    dispatch(fetchDataStart());

    try {
      const storedData = getMeteorologicalData();
      dispatch(fetchDataSuccess(mapMeteorologicalConditions(storedData)));
      dispatch(initializeStoreDataSuccess(moment().format("YYYY-MM-DD H:mm")));
    } catch (error) {
      console.log("Error:", error);
      const message = get(
        error,
        "response.data.error_description",
        "Ocurrio un error al recuperar los datos meteorologicos."
      );
      dispatch(fetchDataFail(message));
    }
  };
}

function updateTimerMilliseconds(dispatch, milliseconds) {
  let momentDate = moment();
  momentDate.add(milliseconds, "milliseconds");
  dispatch(setNextUpdate(momentDate.toDate()));
}

const updateStoreData = (actualData, newData) => {
  return {
    ...actualData,
    ...newData
  };
};
