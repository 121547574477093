import { axiosInstance } from "./api";

export default {
  getData: generator =>
    axiosInstance.get(
      "/generators?$realTimeGeneratorsData=true&generador=" + generator + "&empresa="+ sessionStorage.getItem('empresa') + "&centro=" + sessionStorage.getItem('centro')
    ),
  getPowerHistorical: generator =>
    axiosInstance.get(
      "/generators?$getPowerGeneratorHistory=true&generador=" + generator + "&empresa="+ sessionStorage.getItem('empresa') + "&centro=" + sessionStorage.getItem('centro')
    ),
  getVoltageHistorical: generator =>
    axiosInstance.get(
      "/generators?$getVoltageGeneratorHistory=true&generador=" + generator + "&empresa="+ sessionStorage.getItem('empresa') + "&centro=" + sessionStorage.getItem('centro')
    ),
  getCurrentHistorical: generator =>
    axiosInstance.get(
      "/generators?$getCurrentGeneratorHistory=true&generador=" + generator + "&empresa="+ sessionStorage.getItem('empresa') + "&centro=" + sessionStorage.getItem('centro')
    )
};
