import { axiosAnonInstance } from "./api.js";

export default {
  LoginAuthentication: (mail,password) =>
    axiosAnonInstance.post(process.env.REACT_APP_API_AUTHENTICATION, {
      strategy: "local",//process.env.REACT_APP_API_STRATEGY,
      usuarioMail: mail,// email,//process.env.REACT_APP_API_USER,
      usuarioPassword: password//password//process.env.REACT_APP_API_PASS
    })
};
