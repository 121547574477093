/* eslint-disable no-unused-vars */
import { get } from "lodash-es";
import mapGeneralConditionsDoor from "selectors/mapGeneralConditionsDoor";
import mapGeneralConditionsEmeter from "selectors/mapGeneralConditionsEmeter";
import mapGeneralConditionsTHsensor from "selectors/mapGeneralConditionsTHsensor";
import mapGeneralConditionsUPS from "selectors/mapGeneralConditionsUPS";

import { createActions } from "redux-actions";

var moment = require("moment");
require("moment/locale/es");

const actionOptions = {
  prefix: "GENERAL",
  namespace: "-"
};

export const {
  // fetchDataStart,
  // fetchDataSuccess,
  // fetchDataFail,

  fetchDataUpsStart,
  fetchDataUpsSuccess,
  fetchDataUpsFail,
  fetchDataEnergyMeterStart,
  fetchDataEnergyMeterSuccess,
  fetchDataEnergyMeterFail,
  fetchDataTempHumStart,
  fetchDataTempHumSuccess,
  fetchDataTempHumFail,
  fetchDataDoorStart,
  fetchDataDoorSuccess,
  fetchDataDoorFail,
  initializeStoreDataStart,
  initializeStoreDataSuccess,
  initializeStoreDataFail,
  setNextUpdate,
  updateUnitDataStore 
} = createActions(
  {
    FETCH_DATA_UPS_START: undefined,
    FETCH_DATA_UPS_SUCCESS: data => ({ data }),
    FETCH_DATA_UPS_FAIL: error => ({ error }),
    FETCH_DATA_ENERGY_METER_START: undefined,
    FETCH_DATA_ENERGY_METER_SUCCESS: data => ({ data }),
    FETCH_DATA_ENERGY_METER_FAIL: error => ({ error }),
    FETCH_DATA_TEMP_HUM_START: undefined,
    FETCH_DATA_TEMP_HUM_SUCCESS: data => ({ data }),
    FETCH_DATA_TEMP_HUM_FAIL: error => ({ error }),
    FETCH_DATA_DOOR_START: undefined,
    FETCH_DATA_DOOR_SUCCESS: data => ({ data }),
    FETCH_DATA_DOOR_FAIL: error => ({ error }),
    INITIALIZE_STORE_DATA_START: undefined,
    INITIALIZE_STORE_DATA_SUCCESS: date => ({ date }),
    INITIALIZE_STORE_DATA_FAIL: error => ({ error }),
    SET_NEXT_UPDATE: date => ({ date }),
    UPDATE_UNIT_DATA: date => ({ date })
  },
  actionOptions
);

export function fetchDataUps(response) {
  return async (dispatch, getState, api) => {
    dispatch(fetchDataUpsStart());
    let data = response;
    //console.log('data => ' + JSON.stringify(data));
    try {
      dispatch(fetchDataUpsSuccess(mapGeneralConditionsUPS(data)));
      dispatch(
        initializeStoreDataSuccess(
          moment(response.horamuestra, "x").format("YYYY-MM-DD H:mm")
        )
      );
    } catch (error) {
      console.log("Error:", error);
      const message = get(
        error,
        "response.data.error_description",
        "Ocurrio un error al recuperar los datos meteorologicos."
      );
      dispatch(fetchDataUpsFail(message));
    }
  };
}
export function fetchDataEnergyMeter(response) {
  return async (dispatch, getState, api) => {
    dispatch(fetchDataEnergyMeterStart());
    let data = response;
    //console.log('EMETER => '+JSON.stringify(data));
    try {
      dispatch(fetchDataEnergyMeterSuccess(mapGeneralConditionsEmeter(data)));
      dispatch(
        initializeStoreDataSuccess(
          moment(response.horamuestra, "x").format("YYYY-MM-DD H:mm")
        )
      );
    } catch (error) {
      console.log("Error:", error);
      const message = get(
        error,
        "response.data.error_description",
        "Ocurrio un error al recuperar los datos meteorologicos."
      );
      dispatch(fetchDataEnergyMeterFail(message));
    }
  };
}
export function fetchDataTempHum(response) {
  return async (dispatch, getState, api) => {
    dispatch(fetchDataTempHumStart());
    //console.log('fetchDataTempHum actions '+JSON.stringify(data));

    let data = response;
    try {
      dispatch(fetchDataTempHumSuccess(mapGeneralConditionsTHsensor(data)));
      dispatch(
        initializeStoreDataSuccess(
          moment(response.horamuestra, "x").format("YYYY-MM-DD H:mm")
        )
      );
    } catch (error) {
      console.log("Error:", error);
      const message = get(
        error,
        "response.data.error_description",
        "Ocurrio un error al recuperar los datos meteorologicos."
      );
      dispatch(fetchDataTempHumFail(message));
    }
  };
}
export function fetchDataDoor(response) {
  return async (dispatch, getState, api) => {
    dispatch(fetchDataDoorStart());
    let data = response;
    try {
      dispatch(fetchDataDoorSuccess(mapGeneralConditionsDoor(data)));
      dispatch(
        initializeStoreDataSuccess(
          moment(response.horamuestra, "x").format("YYYY-MM-DD H:mm")
        )
      );
    } catch (error) {
      console.log("Error:", error);
      const message = get(
        error,
        "response.data.error_description",
        "Ocurrio un error al recuperar los datos meteorologicos."
      );
      dispatch(fetchDataDoorFail(message));
    }
  };
}
export function initializeGeneralStoreData() {
  return async (dispatch, getState, api) => {
    dispatch(initializeStoreDataStart());

    try {
      const responseUps = await api.generalConditions.getUps();
      const responseEmeter = await api.generalConditions.getEnergyMeter();
      const responseTemphum = await api.generalConditions.getTempHum();
      const responseDoor = await api.generalConditions.getDoor();

      let dataUps = responseUps.data.data[0] && responseUps.data.data[0];
      let dataEmeter = responseEmeter.data.data[0] && responseEmeter.data.data[0];
      let dataTempHum = responseTemphum.data.data[0] && responseTemphum.data.data[0];
      let dataDoor = responseDoor.data.data[0] && responseDoor.data.data[0];

      dispatch(fetchDataDoorSuccess(mapGeneralConditionsDoor(dataDoor)));
      dispatch(fetchDataEnergyMeterSuccess(mapGeneralConditionsEmeter(dataEmeter)));
      dispatch(fetchDataTempHumSuccess(mapGeneralConditionsTHsensor(dataTempHum)));
      dispatch(fetchDataUpsSuccess(mapGeneralConditionsUPS(dataUps)));

      dispatch(
        initializeStoreDataSuccess(
          moment(dataTempHum.horamuestra).format("YYYY-MM-DD H:mm")
        )
      );

    } catch (error) {
      console.log("Error:", error);
      const message = get(
        error,
        "response.data.error_description",
        "Ocurrio un error al recuperar los datos meteorologicos."
      );
      dispatch(initializeStoreDataFail(message));
    }
  };
}



function updateTimerMilliseconds(dispatch, milliseconds) {
  let momentDate = moment();
  momentDate.add(milliseconds, "milliseconds");
  dispatch(setNextUpdate(momentDate.toDate()));
}

const updateStoreData = (actualData, newData) => {
  return {
    ...actualData,
    ...newData
  };
};
