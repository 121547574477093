import { createSelector } from "reselect";


const mapGeneralConditionsTH = createSelector(
  data => data,
  data =>
    typeof data !== "undefined" && data !== null
      ? {
        temp: data.sensorth && data.sensorth.Temp,
        hum: data.sensorth && data.sensorth.Hum
      }
      : {
        temp: 0,
        hum: 0
      }
);

export default mapGeneralConditionsTH;
