import { axiosInstance } from "./api";
//http://localhost:3031/centro?empresaId=5&$limit=20
export default {
    getPlacesByCompany: (startdate) =>
        // axiosInstance.get("/centro?empresaId=" + sessionStorage.getItem('empresa') + "&$limit=40"),
        axiosInstance.get("/centro?$sort[centroId]=-1&$limit=40&empresaId=" + sessionStorage.getItem('empresa')),

    getPluviosityData: () =>
        axiosInstance.get(
            "/meteo?$sort[horamuestra]=-1&$limit=1&empresa=" + sessionStorage.getItem('empresa') + "&centro=" + sessionStorage.getItem('centro')
        )
};
