import { createSelector } from "reselect";
import { round } from "utils/math";
import { getCurrentsUnit } from "utils/localStorageHelper";

var moment = require("moment");
require("moment/locale/es");

const currentsData = state => state.currentsData;
const depthData = state => state.depthData;

const mapTidesAndCurrents = createSelector(
  [currentsData, depthData],
  (currentsData, depthData) =>
    typeof currentsData !== "undefined" &&
    currentsData !== null &&
    currentsData.length > 0 &&
    typeof depthData !== "undefined" &&
    depthData !== null &&
    depthData.length > 0
      ? {
          dates: getDates(currentsData),
          keys: getKeys(currentsData),
          lineData: generateLineData(depthData),
          arrowData: generateArrowData(currentsData)
        }
      : {
          dates: [],
          keys: [],
          tempData: [],
          oxiData: [],
          salData: []
        }
);

export default mapTidesAndCurrents;

const getDates = data => {
  const result = data.map(_element => {
    return formatedDate(_element.fecha_full_utc0);
  });
  return result;
};

const formatedDate = date => {
  return moment(date)
    .locale("es")
    .format("dd[\n]DD[\n]HH[h]");
};

const getKeys = data => {
  const keys = [];
  let i = 0;
  data.map(_element => {
    keys.push(_element.nombre + i);
    i++;
  });
  return keys;
};
const generateLineData = data => {
  const newArray = data.map((_element, _index) => {
    const newElement = {
      x: "pos" + _index,
      y: round(_element.profundidad, -2)
    };

    return newElement;
  });

  return newArray;
};

const generateArrowData = data => {
  const newArray = [];

  const newObj0 = {
    measure: "arrow0",
    label: "0 mts.",
    min: 0,
    max: 50
  };
  const newObj10 = {
    measure: "arrow10",
    label: "10 mts.",
    min: 0,
    max: 50
  };
  const newObj20 = {
    measure: "arrow20",
    label: "20 mts.",
    min: 0,
    max: 50
  };
  const newObj30 = {
    measure: "arrow30",
    label: "30 mts.",
    min: 0,
    max: 50
  };
  const newObj40 = {
    measure: "arrow40",
    label: "40 mts.",
    min: 0,
    max: 50
  };

  data.map((_element, _index) => {
    newObj0["pos" + _index] = converUnit(_element.velocidad_cero);
    newObj0["pos" + _index + "Rot"] = round(_element.direccion_cero, -2);
    newObj10["pos" + _index] = converUnit(_element.velocidad_diez);
    newObj10["pos" + _index + "Rot"] = round(_element.direccion_diez, -2);
    newObj20["pos" + _index] = converUnit(_element.velocidad_veinte);
    newObj20["pos" + _index + "Rot"] = round(_element.direccion_veinte, -2);
    newObj30["pos" + _index] = converUnit(_element.velocidad_treinta);
    newObj30["pos" + _index + "Rot"] = round(_element.direccion_treinta, -2);
    newObj40["pos" + _index] = converUnit(_element.velocidad_cuarenta);
    newObj40["pos" + _index + "Rot"] = round(_element.direccion_cuarenta, -2);
  });

  newArray.push(newObj0);
  newArray.push(newObj10);
  newArray.push(newObj20);
  newArray.push(newObj30);
  newArray.push(newObj40);

  return newArray;
};

const converUnit = windspeed => {
  const currentsUnit = getCurrentsUnit();
  //console.log('currentsUnit => '+currentsUnit);
  switch (currentsUnit) {
    case "m/s":
      return round(windspeed, -2);
      //return round(windspeed * 100, -2);
    default:
      return round(windspeed * 100, -2);
  }
};
