import { createSelector } from "reselect";

const mapDesalinationPlant = createSelector(
  data => data,
  data =>
    typeof data !== "undefined" && data !== null
      ? {
          preassures: {
            transducer1: data.presion1,
            transducer2: data.presion2,
            transducer3: data.presion3,
            transducer4: data.presion4,
            pond: data.estanque
          },
          temperature: data.tempagua,
          tds: data.tds,
          flowMeters: {
            product: data.flujoproducto,
            reject: data.flujorechazo
          },
          switches: {
            zeolite: data.zeolita,
            pond: data.nivelalto,
            plant: data.onoff,
            highpump: data.bombaalta,
            lowpump: data.bombabaja,
            solenoid: data.solenoide
          },
          plantStatus: data.estadoplanta,
          motorizedValvule: data.posicionvalvula,
          currentMeters: {
            bomb1: data.corrientebaja,
            bomb2: data.corrientealta
          }
        }
      : {
          preassures: {
            transducer1: 0,
            transducer2: 0,
            transducer3: 0,
            transducer4: 0,
            pond: 0
          },
          temperature: 0,
          tds: 0,
          flowMeters: {
            product: 0,
            reject: 0
          },
          switches: {
            zeolite: 0,
            pond: 0,
            plant: 0,
            highpump: 0,
            lowpump: 0,
            solenoid: 0
          },
          motorizedValvule: 0,
          currentMeters: {
            bomb1: 0,
            bomb2: 0
          }
        }
);

export default mapDesalinationPlant;
