/* eslint-disable no-unused-vars */
import {
  fetchDataStart,
  fetchDataSuccess,
  fetchDataFail,
  initializeStoreDataStart,
  initializeStoreDataSuccess,
  initializeStoreDataFail
} from "./actions";

import { handleActions } from "redux-actions";

const initialState = {
  data: {
    temperature: {
      current: 0,
      min: 0,
      max: 0
    },
    apparentTemperature: {
      current: 0,
      min: 0,
      max: 0
    },
    humidity: {
      current: 0,
      min: 0,
      max: 0
    },
    atmosphericPressure: {
      current: 0,
      min: 0,
      max: 0
    },
    windSpeed: {
      current: 0,
      min: 0,
      max: 0
    },
    windGust: {
      current: 0,
      min: 0,
      max: 0
    },
    annualRainfall: 0,
    monthlyRainfall: 0,
    weeklyRainfall: 0,
    dailyRainfall: 0,
    windDirection: 0,
    windGustDirection: 0,
    uvIndex: {
      current: 0,
      min: 0,
      max: 0
    },
    solarRadiation: {
      current: 0,
      min: 0,
      max: 0
    },
    images: {
      image1: null,
      image2: null,
      image3: null,
      image4: null,
      image5: null,
      image6: null
    }
  },
  loading: false,
  updateTime: null,
  error: ""
};

const meteorologicalConditions = handleActions(
  new Map([
    [
      fetchDataStart,
      (state, action) => ({
        ...state,
        loading: true,
        error: ""
      })
    ],
    [
      fetchDataSuccess,
      (state, action) => ({
        // Mapear correctamente los datos aca
        ...state,
        data: action.payload.data,
        loading: false,
        updateTime: action.payload.data.date
      })
    ],
    [
      fetchDataFail,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload.error
      })
    ],
    [
      initializeStoreDataStart,
      (state, action) => ({
        ...state,
        loading: true,
        error: ""
      })
    ],
    [
      initializeStoreDataSuccess,
      (state, action) => ({
        ...state,
        loading: false,
        updateTime: action.payload.date
      })
    ],
    [
      initializeStoreDataFail,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload.error
      })
    ]
  ]),
  initialState
);

export default meteorologicalConditions;
