import { axiosInstance } from "./api";

export default {
  getUps: generator =>
    axiosInstance.get(
      "/ups?empresa="+ sessionStorage.getItem('empresa') + "&centro=" + sessionStorage.getItem('centro')+"&$limit=1&$sort[horamuestra]=-1"
    ),
  getEnergyMeter: generator =>
    axiosInstance.get(
      "/emeter?empresa="+ sessionStorage.getItem('empresa') + "&centro=" + sessionStorage.getItem('centro')+"&$limit=1&$sort[horamuestra]=-1"
    ),
  getTempHum: generator =>
    axiosInstance.get(
      "/thsensor?empresa="+ sessionStorage.getItem('empresa') + "&centro=" + sessionStorage.getItem('centro')+"&$limit=1&$sort[horamuestra]=-1"
    ),
  getDoor: generator =>
    axiosInstance.get(
      "/door?empresa="+ sessionStorage.getItem('empresa') + "&centro=" + sessionStorage.getItem('centro')+"&$limit=1&$sort[horamuestra]=-1"
    )
};