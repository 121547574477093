import { createSelector } from "reselect";
import { round } from "utils/math";
import { variance } from "d3";

var moment = require("moment");
require("moment/locale/es");

const generalData = state => state.generalData;
const powerHistorical = state => state.powerHistorical;
const currentHistorical = state => state.currentHistorical;
const voltageHistorical = state => state.voltageHistorical;

const mapGenerators = createSelector(
  [generalData, powerHistorical, currentHistorical, voltageHistorical],
  (generalData, powerHistorical, currentHistorical, voltageHistorical) =>
    typeof generalData !== "undefined" &&
    generalData !== null &&
    typeof powerHistorical !== "undefined" &&
    powerHistorical !== null &&
    typeof currentHistorical !== "undefined" &&
    currentHistorical !== null &&
    typeof voltageHistorical !== "undefined" &&
    voltageHistorical !== null
      ? {
          mainData: {
            oil: generalData.general.preaceite,
            refTemp: generalData.general.temprefri,
            oilTemp: "0",
            gas: "0",
            batery: generalData.general.voltbater,
            frec: generalData.general.frecuencia,
            rpm: generalData.general.revmotor,
            factorPotencial: generalData.general.factorpottotal,
            totalPotenciaReactiva: generalData.general.potreactivatotal,
            tiempofuncmotor: Math.round((generalData.general.tiempofuncmotor / 3600) * 10) / 10
          },
          parametrosL1: {
            voltaje: generalData.linea1.voltaje,
            corriente: generalData.linea1.corriente,
            potencia: generalData.linea1.potenciaaparente,
            potenciaReactiva: generalData.linea1.potenciareactiva,
            potenciaReactivaPorcentaje: calculatePercentaje(
              generalData.linea1.potenciareactiva,
              generalData.linea1.potenciareactiva +
                generalData.linea2.potenciareactiva +
                generalData.linea3.potenciareactiva
            )
          },
          parametrosL2: {
            voltaje: generalData.linea2.voltaje,
            corriente: generalData.linea2.corriente,
            potencia: generalData.linea2.potenciaaparente,
            potenciaReactiva: generalData.linea2.potenciareactiva,
            potenciaReactivaPorcentaje: calculatePercentaje(
              generalData.linea2.potenciareactiva,
              generalData.linea1.potenciareactiva +
                generalData.linea2.potenciareactiva +
                generalData.linea3.potenciareactiva
            )
          },
          parametrosL3: {
            voltaje: generalData.linea3.voltaje,
            corriente: generalData.linea3.corriente,
            potencia: generalData.linea3.potenciaaparente,
            potenciaReactiva: generalData.linea3.potenciareactiva,
            potenciaReactivaPorcentaje: calculatePercentaje(
              generalData.linea3.potenciareactiva,
              generalData.linea1.potenciareactiva +
                generalData.linea2.potenciareactiva +
                generalData.linea3.potenciareactiva
            )
          },
          voltageChart: {
            MinValue: getMinChartValue(voltageHistorical.extra[0], "voltaje"),
            MaxValue: getMaxChartValue(voltageHistorical.extra[0], "voltaje"),
            linea1Color: "Blue",
            linea1Name: "Línea 1",
            linea1Min: round(voltageHistorical.extra[0].linea1_voltaje_min, -1),
            linea1Avg: round(voltageHistorical.extra[0].linea1_voltaje_avg, -1),
            linea1Max: round(voltageHistorical.extra[0].linea1_voltaje_max, -1),
            linea2Color: "Red",
            linea2Name: "Línea 2",
            linea2Min: round(voltageHistorical.extra[0].linea2_voltaje_min, -1),
            linea2Avg: round(voltageHistorical.extra[0].linea2_voltaje_avg, -1),
            linea2Max: round(voltageHistorical.extra[0].linea2_voltaje_max, -1),
            linea3Color: "Green",
            linea3Name: "Línea 3",
            linea3Min: round(voltageHistorical.extra[0].linea3_voltaje_min, -1),
            linea3Avg: round(voltageHistorical.extra[0].linea3_voltaje_avg, -1),
            linea3Max: round(voltageHistorical.extra[0].linea3_voltaje_max, -1),
            serie: generateArrayVoltaje(
              voltageHistorical.data,
              ChartFieldName.VOLTAGE
            )
          },
          powerChart: {
            MinValue: getMinChartValue(
              powerHistorical.extra[0],
              "potenciaaparente"
            ),
            MaxValue: getMaxChartValue(
              powerHistorical.extra[0],
              "potenciaaparente"
            ),
            linea1Color: "Blue",
            linea1Name: "Línea 1",
            linea1Min: round(
              powerHistorical.extra[0].linea1_potenciaaparente_min,
              -1
            ),
            linea1Avg: round(
              powerHistorical.extra[0].linea1_potenciaaparente_avg,
              -1
            ),
            linea1Max: round(
              powerHistorical.extra[0].linea1_potenciaaparente_max,
              -1
            ),
            linea2Color: "Red",
            linea2Name: "Línea 2",
            linea2Min: round(
              powerHistorical.extra[0].linea2_potenciaaparente_min,
              -1
            ),
            linea2Avg: round(
              powerHistorical.extra[0].linea2_potenciaaparente_avg,
              -1
            ),
            linea2Max: round(
              powerHistorical.extra[0].linea2_potenciaaparente_max,
              -1
            ),
            linea3Color: "Green",
            linea3Name: "Línea 3",
            linea3Min: round(
              powerHistorical.extra[0].linea3_potenciaaparente_min,
              -1
            ),
            linea3Avg: round(
              powerHistorical.extra[0].linea3_potenciaaparente_avg,
              -1
            ),
            linea3Max: round(
              powerHistorical.extra[0].linea3_potenciaaparente_max,
              -1
            ),
            serie: generateArrayVoltaje(
              powerHistorical.data,
              ChartFieldName.POWER
            )
          },
          currentChart: {
            MinValue: getMinChartValue(currentHistorical.extra[0], "corriente"),
            MaxValue: getMaxChartValue(currentHistorical.extra[0], "corriente"),
            linea1Color: "Blue",
            linea1Name: "Línea 1",
            linea1Min: round(
              currentHistorical.extra[0].linea1_corriente_min,
              -1
            ),
            linea1Avg: round(
              currentHistorical.extra[0].linea1_corriente_avg,
              -1
            ),
            linea1Max: round(
              currentHistorical.extra[0].linea1_corriente_max,
              -1
            ),
            linea2Color: "Red",
            linea2Name: "Línea 2",
            linea2Min: round(
              currentHistorical.extra[0].linea2_corriente_min,
              -1
            ),
            linea2Avg: round(
              currentHistorical.extra[0].linea2_corriente_avg,
              -1
            ),
            linea2Max: round(
              currentHistorical.extra[0].linea2_corriente_max,
              -1
            ),
            linea3Color: "Green",
            linea3Name: "Línea 3",
            linea3Min: round(
              currentHistorical.extra[0].linea3_corriente_min,
              -1
            ),
            linea3Avg: round(
              currentHistorical.extra[0].linea3_corriente_avg,
              -1
            ),
            linea3Max: round(
              currentHistorical.extra[0].linea3_corriente_max,
              -1
            ),
            serie: generateArrayVoltaje(
              currentHistorical.data,
              ChartFieldName.CURRENT
            )
          }
        }
      : {
          mainData: {
            oil: 0,
            refTemp: 0,
            oilTemp: 0,
            gas: 0,
            batery: 0,
            frec: 0,
            rpm: 0,
            totalPotenciaReactiva: 0,
            factorPotencial: 0
          },
          parametrosL1: {
            voltaje: 0,
            corriente: 0,
            potencia: 0,
            potenciaReactiva: 0,
            potenciaReactivaPorcentaje: 0
          },
          parametrosL2: {
            voltaje: 0,
            corriente: 0,
            potencia: 0,
            potenciaReactiva: 0,
            potenciaReactivaPorcentaje: 0
          },
          parametrosL3: {
            voltaje: 0,
            corriente: 0,
            potencia: 0,
            potenciaReactiva: 0,
            potenciaReactivaPorcentaje: 0
          },
          voltageChart: {
            MinValue: 0,
            MaxValue: 0,
            linea1Color: "blue",
            linea1Name: "Línea 1",
            linea1Min: 0,
            linea1Avg: 0,
            linea1Max: 0,
            linea2Color: "red",
            linea2Name: "Línea 2",
            linea2Min: 0,
            linea2Avg: 0,
            linea2Max: 0,
            linea3Color: "green",
            linea3Name: "Línea 3",
            linea3Min: 0,
            linea3Avg: 0,
            linea3Max: 0,
            serie: []
          },
          powerChart: {
            MinValue: 0,
            MaxValue: 0,
            linea1Min: 0,
            linea1Avg: 0,
            linea1Max: 0,
            linea2Min: 0,
            linea2Avg: 0,
            linea2Max: 0,
            linea3Min: 0,
            linea3Avg: 0,
            linea3Max: 0,
            serie: []
          },
          currentChart: {
            MinValue: 0,
            MaxValue: 0,
            linea1Min: 0,
            linea1Avg: 0,
            linea1Max: 0,
            linea2Min: 0,
            linea2Avg: 0,
            linea2Max: 0,
            linea3Min: 0,
            linea3Avg: 0,
            linea3Max: 0,
            serie: []
          }
        }
);

const ChartFieldName = {
  VOLTAGE: "voltaje",
  POWER: "potencia",
  CURRENT: "corriente"
};

const calculatePercentaje = (value, total) => {
  var percentaje = (value / total) * 100;
  return round(percentaje, -1);
};

const generateArrayVoltaje = (dataArray, chartFieldName) => {
  const newArray = [];

  const linea1 = InitLineObject("line1", "#377eb8", "Línea 1");
  const linea2 = InitLineObject("line2", "#ac4849", "Línea 2");
  const linea3 = InitLineObject("line3", "#4daf4a", "Línea 3");

  dataArray.reverse().map(item => {
    linea1.data.push({
      x: formatedDate(item.fecha_full_utc0),
      y: item["linea1_" + chartFieldName]
    });
    linea2.data.push({
      x: formatedDate(item.fecha_full_utc0),
      y: item["linea2_" + chartFieldName]
    });
    linea3.data.push({
      x: formatedDate(item.fecha_full_utc0),
      y: item["linea3_" + chartFieldName]
    });
  });

  newArray.push(linea1);
  newArray.push(linea2);
  newArray.push(linea3);

  return newArray;
};

const InitLineObject = (id, color, name) => {
  return {
    id: id,
    color: color,
    name: name,
    data: []
  };
};

const formatedDate = date => {
  return moment(date)
    .locale("es")
    .format("h:mm");
};

const getMinChartValue = (chartValues, type) => {
  let minValue = Math.min(
    chartValues["linea1_" + type + "_min"],
    chartValues["linea2_" + type + "_min"],
    chartValues["linea3_" + type + "_min"]
  );

  return minValue * 0.9;
};

const getMaxChartValue = (chartValues, type) => {
  let max = Math.max(
    chartValues["linea1_" + type + "_max"],
    chartValues["linea2_" + type + "_max"],
    chartValues["linea3_" + type + "_max"]
  );
  return max * 1.1;
};

export default mapGenerators;
