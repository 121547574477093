import { createSelector } from "reselect";
import { round } from "utils/math";

var moment = require("moment");
require("moment/locale/es");

const mapWaterConditions = createSelector(
  conditionsList => conditionsList,
  conditionsList =>
    typeof conditionsList !== "undefined" &&
    conditionsList !== null &&
    conditionsList.length > 0
      ? {
          dates: getDates(conditionsList),
          keys: getKeys(conditionsList),
          tempData: generateArrayTemp(conditionsList),
          oxiData: generateArrayOxi(conditionsList),
          salData: generateArraySal(conditionsList)
        }
      : {
          dates: [],
          keys: [],
          tempData: [],
          oxiData: [],
          salData: []
        }
);

export default mapWaterConditions;

const getDates = data => {
  const result = data.map(_element => {
    return formatedDate(_element.fecha_full_utc0);
  });
  return result;
};

const formatedDate = date => {
  return moment(date)
    .locale("es")
    .format("dd[\n]DD[\n]HH[h]");
};

const getKeys = data => {
  const keys = [];
  let i = 0;
  data.map(_element => {
    keys.push(_element.nombre + i);
    i++;
  });
  return keys;
};

const generateArrayTemp = data => {
  const newArray = [];
  const newObj0 = {
    measure: "temp0"
  };
  const newObj10 = {
    measure: "temp10"
  };
  const newObj15 = {
    measure: "temp15"
  };
  // const newObj20 = {
  //   measure: "temp20"
  // };
  let i = 0;
  data.map(_element => {
    newObj0[_element.nombre + i] = round(_element.temp_cinco, -2);
    newObj10[_element.nombre + i] = round(_element.temp_diez, -2);
    newObj15[_element.nombre + i] = round(_element.temp_quince, -2);
    // newObj20[_element.nombre + i] = round(_element.temp_veinte, -2);
    i++;
  });
  newArray.push(newObj0);
  newArray.push(newObj10);
  newArray.push(newObj15);
  // newArray.push(newObj20);
  return newArray;
};

const generateArrayOxi = data => {
  const newArray = [];
  const newObj0 = {
    measure: "temp0"
  };
  const newObj10 = {
    measure: "temp10"
  };
  const newObj15 = {
    measure: "temp15"
  };
  // const newObj20 = {
  //   measure: "temp20"
  // };
  let i = 0;
  data.map(_element => {
    newObj0[_element.nombre + i] = round(_element.oxigeno_cinco, -2);
    newObj10[_element.nombre + i] = round(_element.oxigeno_diez, -2);
    newObj15[_element.nombre + i] = round(_element.oxigeno_quince, -2);
    // newObj20[_element.nombre + i] = round(_element.oxigeno_veinte, -2);
    i++;
  });
  newArray.push(newObj0);
  newArray.push(newObj10);
  newArray.push(newObj15);
  // newArray.push(newObj20);
  return newArray;
};

const generateArraySal = data => {
  const newArray = [];
  const newObj0 = {
    measure: "temp0"
  };
  const newObj10 = {
    measure: "temp10"
  };
  const newObj15 = {
    measure: "temp15"
  };
  // const newObj20 = {
  //   measure: "temp20"
  // };
  let i = 0;
  data.map(_element => {
    newObj0[_element.nombre + i] = round(_element.salinidad_cinco, -2);
    newObj10[_element.nombre + i] = round(_element.salinidad_diez, -2);
    newObj15[_element.nombre + i] = round(_element.salinidad_quince, -2);
    // newObj20[_element.nombre + i] = round(_element.salinidad_veinte, -2);
    i++;
  });
  newArray.push(newObj0);
  newArray.push(newObj10);
  newArray.push(newObj15);
  // newArray.push(newObj20);
  return newArray;
};
