import { combineReducers } from "redux";
import modal from "./modal/reducer";
import generalConditions from "store/generalConditions/reducer";
import meteorologicalConditions from "store/meteorologicalConditions/reducer";
import waterConditions from "store/waterConditions/reducer";
import oceanographicData from "store/oceanographicData/reducer";
import tidesAndCurrents from "store/tidesAndCurrents/reducer";
import desalinationPlant from "store/desalinationPlant/reducer";
import treatmentPlant from "store/treatmentPlant/reducer";
import generators from "store/generators/reducer";
import settings from "store/settings/reducer";
import login from "store/login/reducer";

export default combineReducers({
  modal,
  generalConditions,
  meteorologicalConditions,
  waterConditions,
  tidesAndCurrents,
  oceanographicData,
  desalinationPlant,
  treatmentPlant,
  generators,
  settings,
  login
});
