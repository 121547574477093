/* eslint-disable no-unused-vars */
import { createActions } from "redux-actions";
import { get } from "lodash-es";
import {
  getWaterConditionsData,
  setWaterConditionsData,
  getWaterConditionsLastUpdate
} from "utils/localStorageHelper";
import mapWaterConditions from "selectors/mapWaterConditions";

var moment = require("moment");
require("moment/locale/es");

const updateTime = 600000;
const retryTime = 10000;

const actionOptions = {
  prefix: "WATERCONDITIONS", // Prefijo usado para los actions creados
  namespace: "-" // Separador usado entre el prefijo y el nombre del action
  // No usar "_" como separador, o se rompe el naming de las variables
};

export const {
  fetchDataStart,
  fetchDataSuccess,
  fetchDataFail,
  initializeStoreDataStart,
  initializeStoreDataSuccess,
  initializeStoreDataFail
} = createActions(
  {
    FETCH_DATA_START: undefined, // Este action no tiene payload
    FETCH_DATA_SUCCESS: data => ({ data }),
    FETCH_DATA_FAIL: error => ({ error }),
    INITIALIZE_STORE_DATA_START: undefined,
    INITIALIZE_STORE_DATA_SUCCESS: date => ({ date }),
    INITIALIZE_STORE_DATA_FAIL: error => ({ error })
  },
  actionOptions
);

export function initializeWaterStoreData() {
  return async dispatch => {
    dispatch(initializeStoreDataStart());

    try {
      let storeData = getWaterConditionsData();

      if (storeData == null) {
        await dispatch(fetchWaterData()); // ACTUALIZA FECHA
      } else {
        dispatch(fetchDataSuccess(storeData));
        dispatch(initializeStoreDataSuccess());
      }
    } catch (error) {
      console.log("Error:", error);
      const message = get(
        error,
        "response.data.error_description",
        "Ocurrio un error al recuperar los datos de condiciones del agua."
      );
      dispatch(initializeStoreDataFail(message));
    }
  };
}

export function fetchWaterData() {
  return async (dispatch, getState, api) => {
    dispatch(fetchDataStart());

    return api.oceanographicData
      .getDailyWaterConditionsData()
      .then(async response => {
        let data = mapWaterConditions(response.data.MultiLastDay[0].data);
        dispatch(fetchDataSuccess(data));
        setWaterConditionsData(data);
      })
      .catch(error => {
        console.log("Error:", error);
        const message = get(
          error,
          "response.data.error_description",
          "An error occurred while logging in. Please try again."
        );
        dispatch(fetchDataFail(message));
      });
  };
}