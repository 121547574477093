/* eslint-disable no-unused-vars */
import {
  getWindUnit,
  setWindUnit,
  getCurrentsUnit,
  setCurrentsUnit,
  getTheme,
  setTheme
} from "utils/localStorageHelper";
import { createActions } from "redux-actions";

// ------------------- //
// ACTIONS SINCRÓNICOS //
// ------------------- //

const actionOptions = {
  prefix: "SETTINGS", // Prefijo usado para los actions creados
  namespace: "-" // Separador usado entre el prefijo y el nombre del action
  // No usar "_" como separador, o se rompe el naming de las variables
};

// Los nombres de las variables siempre se pasan de snake_case a camelCase
export const {
  initializeSettingsSuccess,
  updateSettings,
  updateCurrentsSettings,
  updateTheme
} = createActions(
  {
    INITIALIZE_SETTINGS_SUCCESS: data => ({ data }),
    UPDATE_SETTINGS: data => ({ data }),
    UPDATE_CURRENTS_SETTINGS: data => ({ data }),
    UPDATE_THEME: data => ({ data })
  },
  actionOptions
);

export function initializeStoreSettings() {
  return async dispatch => {
    let storeData = getWindUnit();
    if (storeData === null) { 
      storeData = "km/h";
      setWindUnit(storeData);
    }
    dispatch(initializeSettingsSuccess(storeData));

    let currentsStoreData = getCurrentsUnit();
    if (currentsStoreData === null) {
      currentsStoreData = "cm/s";
      setCurrentsUnit(currentsStoreData);
    }
    dispatch(updateCurrentsSettings(currentsStoreData));

    let themeStoreData = getTheme();
    if (themeStoreData === null) {
      themeStoreData = "dark";
      setTheme(themeStoreData);
    }
    dispatch(updateTheme(themeStoreData));
  };
}

export function updateWindUnitStorage(windUnit) {
  return async dispatch => {
    await setWindUnit(windUnit);
    dispatch(initializeSettingsSuccess(windUnit));
  };
}

export function updateCurrentsUnitStorage(windUnit) {
  return async dispatch => {
    await setCurrentsUnit(windUnit);
    dispatch(updateCurrentsSettings(windUnit));
  };
}

export function updateThemeStorage(theme) {
  return async dispatch => {
    await setTheme(theme);
    dispatch(updateTheme(theme));
  };
}
