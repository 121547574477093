import {
  drawerWidth,
  transition,
  boxShadow,
  infoColor,
  whiteColor,
  blackColor,
  grayColor,
  hexToRgb
} from "assets/jss/mainStyle.js"; 

const styles = theme => ({
  drawerPaper: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    right: "0",
    zIndex: "1",
    ...boxShadow,
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      position: "fixed",
      height: "100%"
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
      ...boxShadow,
      position: "fixed",
      display: "block",
      height: "100vh",
      top: "0",
      bottom: "0",
      right: "0",
      zIndex: "1032",
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition
    }
  },
  drawerPaperRTL: {
    [theme.breakpoints.up("md")]: {
      right: "auto !important",
      left: "0 !important"
    },
    [theme.breakpoints.down("sm")]: {
      right: "0  !important",
      left: "auto !important"
    }
  },
  background: {
    position: "absolute",
    zIndex: "1",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      background: blackColor,
      opacity: ".8"
    }
  },
  whiteFont: {
    color: whiteColor
  },
  sidebarWrapper: {
    position: "relative",
    overflow: "auto",
    width: "260px",
    zIndex: "4",
    overflowScrolling: "touch"
  },
  activePro: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      width: "100%",
      bottom: "13px"
    }
  },
  titleHeader: {
    position: "relative",
    padding: "25px 15px",
    textAlign: "center",
    zIndex: "4",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "0",
      height: "1px",
      right: "15px",
      width: "calc(100% - 30px)",
      backgroundColor: "rgba(" + hexToRgb(grayColor[6]) + ", 0.3)"
    }
  },
  title: {
    fontSize: 16,
    color: "#FFFFFF",
    fontFamily: "'Open Sans', sans-serif",
    textTransform: "uppercase",
    fontWeight: "bold"
  },
  subtitleHeader: {
    position: "relative",
    padding: "20px 5px",
    textAlign: "left",
    zIndex: "4",
    paddingLeft: 20
  },
  subtitle: {
    fontSize: 13,
    color: "#FFFFFF",
    fontFamily: "'Open Sans', sans-serif",
    textTransform: "uppercase",
    fontWeight: "bold"
  },
  selected: {
    backgroundColor: infoColor[0],
    boxShadow:
      "0 12px 20px -10px rgba(" +
      hexToRgb(infoColor[0]) +
      ",.28), 0 4px 20px 0 rgba(" +
      hexToRgb(blackColor) +
      ",.12), 0 7px 8px -5px rgba(" +
      hexToRgb(infoColor[0]) +
      ",.2)",
    "&:hover,&:focus": {
      backgroundColor: infoColor[0],
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(infoColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(infoColor[0]) +
        ",.2)"
    }
  },
  optionsLabels: {
    fontFamily: "'Open Sans',  sans-serif",
    padding: 10,
    borderRadius: "8px",
    color: "#FFFFFF",
    cursor: "pointer",
    textAlign: "center"
  }
});

export default styles;
