import { local } from "d3";
import { axiosInstance } from "./api";

export default {
  getData: () =>
    axiosInstance.get("/meteo?$realTimeMeteoData=true&empresa=" + sessionStorage.getItem('empresa') + "&centro=" + sessionStorage.getItem('centro')),
  getPluviosityData: () =>
    axiosInstance.get(
      "/meteo?$sort[horamuestra]=-1&$limit=1&empresa=" + sessionStorage.getItem('empresa') + "&centro=" + sessionStorage.getItem('centro')
    )
};
