/* eslint-disable react/prop-types */
import React from "react";
// import "./App.css";
import { AppRoutes } from "./routes";
// import { ThemeProvider } from "@material-ui/core/styles";
// import MainTheme from "./themes/main";
import { connect } from "react-redux";

import ModalRootContainer from "containers/common/modalRoot/modalRoot";

import { Router } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { createBrowserHistory } from "history";
import Layout from "components/layouts/layout/layout";
import SettingsManagerContainer from "containers/common/settingsManager/settingsManager";
import DataControllerContainer from "containers/common/dataController/dataController";

import backgroudImage from "assets/img/background2.jpg";
import lightBackgroundImage from "assets/img/light-background2.jpg";
import Login from "components/pages/login/Login";
import { getAuthToken } from "utils/auth.js";


// eslint-disable-next-line no-unused-vars
function mapStateToProps(state) {
  const { settings } = state;

  return {
    theme: settings.theme
  };
}

const hist = createBrowserHistory();

// eslint-disable-next-line no-unused-vars
const unlisten = hist.listen((location, action) => {});

const darkTheme = createMuiTheme({
  backgroundImg: `url(${backgroudImage})`,
  background: "rgba(0, 0, 0, 0.5)",
  color: "#FFFFFF",
  chartTextColor: "#FFFFFF",
  borderColor: "#b6c2c9",
  titleColor: "#e3e3e3",
  axisBarColor: "#FFFFFF",
  axisTextBarColor: "#FFFFFF",
  markerColor: "#FFFFFF",
  tACLineColor: "#5cfff5",
  tACBackLineColor: "white",
  generatorLegendColor: "white",
  svgColor: "#FFF",
  svgFilledTextColor: "black"
});

const lightTheme = createMuiTheme({
  backgroundImg: `url(${lightBackgroundImage})`,
  background: "rgba(255, 255, 255, 0.4)",
  color: "black",
  chartTextColor: "black",
  borderColor: "black",
  titleColor: "black ",
  axisBarColor: "black",
  axisTextBarColor: "black",
  markerColor: "black",
  tACLineColor: "#5c96ff",
  tACBackLineColor: "black",
  generatorLegendColor: "black",
  svgColor: "black",
  svgFilledTextColor: "#FFF"
});


// eslint-disable-next-line no-unused-vars
function App(props) {
  React.useEffect(() => {
    return () => {
      unlisten();
    };
  }, []);

  var token = getAuthToken();

  return (
    <div className="App">
      <React.Fragment>
        <Router history={hist}>
          {token !== null && token !== undefined ? (
            <DataControllerContainer/>
          ) : null}

          <SettingsManagerContainer />
          <MuiThemeProvider
            theme={props.theme === "light" ? lightTheme : darkTheme}
          >
           {/* {!props.showMe ? <LoginBlumar /> : <Layout>{AppRoutes}</Layout>} */}
           <Layout>{AppRoutes}</Layout>

          </MuiThemeProvider>
        </Router>
        <ModalRootContainer />
      </React.Fragment>
    </div>
  );
}

export default connect(mapStateToProps)(App);
