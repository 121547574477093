import { axiosInstance } from "./api";

export default {
  getDailyWaterConditionsData: () =>
    axiosInstance.get("/ocea?$getMultiLastDay=true&empresa=" + sessionStorage.getItem('empresa') + "&centro=" + sessionStorage.getItem('centro')),
  getWeeklyWaterConditionsData: () =>
    axiosInstance.get("/ocea?$getMultiHistory=true&&empresa="+ sessionStorage.getItem('empresa') + "&centro=" + sessionStorage.getItem('centro')),
  getDailyTidesAndCurrentsData: () =>
    axiosInstance.get("/ocea?$getCurrentLastDay=true&&empresa="+ sessionStorage.getItem('empresa') + "&centro=" + sessionStorage.getItem('centro')),
  getWeeklyTidesAndCurrentsData: () =>
    axiosInstance.get("/ocea?$getCurrentHistory=true&&empresa="+ sessionStorage.getItem('empresa') + "&centro=" + sessionStorage.getItem('centro')),
  getDailyDepthsData: () =>
    axiosInstance.get("/ocea?$getEcoLastDay=true&&empresa="+ sessionStorage.getItem('empresa') + "&centro=" + sessionStorage.getItem('centro'))
};
