/* eslint-disable no-unused-vars */
import {
  fetchDataStart,
  fetchDataSuccess,
  fetchDataFail,
  initializeStoreDataStart,
  initializeStoreDataSuccess,
  initializeStoreDataFail
} from "./actions";

import { handleActions } from "redux-actions";

const initialState = {
  data: {
    preassures: {
      transducer1: 0,
      transducer2: 0,
      transducer3: 0,
      transducer4: 0,
      pond: 0
    },
    temperature: 0,
    tds: 0,
    flowMeters: {
      product: 0,
      reject: 0
    },
    switches: {
      zeolite: 0,
      pond: 0,
      plant: 0,
      highpump: 0,
      lowpump: 0,
      solenoid: 0
    },
    motorizedValvule: 0,
    currentMeters: {
      bomb1: 0,
      bomb2: 0
    }
  },
  loading: false,
  updateTime: "",
  error: ""
};

const desalinationPlant = handleActions(
  new Map([
    [
      fetchDataStart,
      state => ({
        ...state,
        loading: true,
        error: ""
      })
    ],
    [
      fetchDataSuccess,
      (state, action) => ({
        ...state,
        data: action.payload.data,
        loading: false,
        updateTime: action.payload.date
      })
    ],
    [
      fetchDataFail,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload.error
      })
    ],
    [
      initializeStoreDataStart,
      state => ({
        ...state,
        loading: true,
        error: ""
      })
    ],
    [
      initializeStoreDataSuccess,
      (state, action) => ({
        ...state,
        loading: false,
        updateTime: action.payload.date
      })
    ],
    [
      initializeStoreDataFail,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload.error
      })
    ]
  ]),
  initialState
);

export default desalinationPlant;
