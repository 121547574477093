import { createSelector } from "reselect";


const mapGeneralConditionsDoor = createSelector(
  data => data,
  data =>
    typeof data !== "undefined" && data !== null
      ? {
        estado: data.puerta1 && data.puerta1.estado
      }
      : {
        estado: false
      }
);

export default mapGeneralConditionsDoor;
